import { CommunitiesBreadcrumb } from "communities/CommunitiesBreadcrumb";
import { Container } from "@material-ui/core";
import { CommunitiesImageSlider } from "communities/CommunitiesImageSlider";
import { CommunitiesInformationSection } from "communities/CommunitiesInformationSection";
import { Button } from "_common/ui/mui/buttons/Button";
import React, { lazy, Suspense, useMemo, useState } from "react";
import { TCommunitiesListingMdl } from "admin/communities/_models/CommunitiesMdl";
import { CommunityStore } from "communities/_stores/communityStore";
import { CommunityContext } from "communities/CommunityContext";
import { CommunitiesCardSliderContainer } from "communities/components/CommunitiesCardSliderContainer";
import { Loader } from "_common/loaders/Loader";
import { CommunitiesTitle } from "communities/components/CommunitiesTitle";
import { CommunitiesFeaturesAndAmenties } from "communities/components/CommunitiesFeaturesAndAmenties";
import { CommunitiesUsefulFacts } from "communities/components/CommunitiesUsefulFacts";
import { URLS } from "_configs/URLS";
import { Link } from "react-router-dom";
import { CommunitiesDiscover } from "communities/components/CommunitiesDiscover";
import { useTranslation } from "react-i18next";
import { CommunitiesContactFormDialog } from "communities/components/CommunitiesContactFormDialog";

const CommunitiesActivitiesMap = lazy(() => import("./components/CommunitiesActivitiesMap"));

type Props = {
    community: TCommunitiesListingMdl;
};

export function CommunityView({ community }: Props) {
    const communityStore = useMemo(() => new CommunityStore(community), [community]);
    const [contactFormVisible, setContactFormVisible] = useState(false);
    const { t } = useTranslation();

    return (
        <CommunityContext communityStore={communityStore}>
            <Container className=" !max-w-[1260px] mb-[25px]">
                <CommunitiesBreadcrumb />
            </Container>

            <Container className=" !max-w-[1260px] mb-[25px]">
                <CommunitiesImageSlider />
            </Container>

            <Container className="!max-w-[1260px] mb-[25px]">
                <CommunitiesInformationSection />
            </Container>

            <Container className=" !max-w-[1260px] mb-[75px]">
                <CommunitiesContactFormDialog
                    isOpen={contactFormVisible}
                    setIsOpen={() => setContactFormVisible(false)}
                />
                <Button variant={"contained"} onClick={() => setContactFormVisible(true)}>
                    {t("communitiesPage.requestInfo")}
                </Button>
            </Container>

            <Container className=" !max-w-[1260px] mb-[25px]">
                <CommunitiesCardSliderContainer />
            </Container>

            <Container className="!flex !justify-center  !max-w-[1260px] mb-[75px]">
                <Link to={URLS.buy()}>
                    <Button variant={"outlined"}>{t("communitiesPage.viewAllProperties")}</Button>
                </Link>
            </Container>

            <Container className=" !max-w-[1260px] mb-[75px]">
                <CommunitiesTitle
                    title={`${t("communitiesPage.discover")} ${community.localized.title}`}
                    delimiter={1}
                />
                <CommunitiesDiscover videoLink={community.videoLink} />
            </Container>

            <Container className=" !max-w-[1260px] mb-[75px]">
                <CommunitiesTitle title={"The Map"} delimiter={1} />
                <Suspense fallback={<Loader />}>
                    <CommunitiesActivitiesMap />
                </Suspense>
            </Container>

            <Container className=" !max-w-[1260px] mb-[75px]">
                <CommunitiesFeaturesAndAmenties />
            </Container>

            <Container className=" !max-w-[1260px] mb-[75px]">
                <CommunitiesUsefulFacts />
            </Container>
        </CommunityContext>
    );
}

import React from "react";
import bed from "../../../assets/images/icons/bed.svg";
import { useTranslation } from "react-i18next";

type Props = {
    min: number;
    max?: number;
};

export function PropertyCardBeds({ min, max }: Props) {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex items-center justify-center">
                <img src={bed} alt={"bed icon"} />
            </div>
            {max && max != min ? (
                <>
                    {t("property.card.bedrooms.rangeOfRooms", {
                        min: min,
                        max: max,
                        context: `${min}`,
                    })}
                </>
            ) : (
                <>
                    {t("property.card.bedrooms.numberOfRoom", {
                        min: min,
                        context: `${min}`,
                    })}
                </>
            )}
        </>
    );
}

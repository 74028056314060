import React, { CSSProperties, useState } from "react";
import { TComponent } from "pages/_models/PageMdl";
import { ChildrenComponentRenderer } from "components/ChildrenComponentRenderer";
import { Collapse } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Props = {
    label?: string;
    style?: CSSProperties;
    children: TComponent[];
    $component: TComponent;
};

export function SeeMoreComponent({ children, $component, ...props }: Props) {
    const [opened, setOpened] = useState(false);
    const { t } = useTranslation();
    return (
        <div {...props}>
            <Collapse in={!opened}>
                <div className="flex items-center justify-center">
                    <div className="btn btn_1" onClick={() => setOpened(true)}>
                        {props.label ?? t("news.seeMore")}
                    </div>
                </div>
            </Collapse>
            <Collapse in={opened}>
                <ChildrenComponentRenderer propName="children" parent={$component}>
                    {children ?? []}
                </ChildrenComponentRenderer>
            </Collapse>
        </div>
    );
}

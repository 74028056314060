import React from "react";
import { useTranslation } from "react-i18next";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { ListStore } from "_common/list/ListStore";
import { observer } from "mobx-react-lite";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { numberFormatter } from "_common/_utils/currencyUtils";
import { useSearchPageParams } from "_common/_utils/hookUtils";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

export const PropertiesCount = observer(({ listStore }: Props) => {
    const { t } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const { isLuxury } = useSearchPageParams();
    return (
        <div className={"text-xs font-light flex items-center gap-1"}>
            <div>
                {t("propertiesPage.listingNumber", {
                    count: listStore.count,
                })}
            </div>
            {!isLuxury && (
                <>
                    <div>/</div>
                    <div>
                        {t("propertiesPage.listingUnitsMatched", {
                            number: numberFormatter(propertiesStore.countUnits),
                        })}
                    </div>
                </>
            )}
        </div>
    );
});

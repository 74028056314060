import React from "react";
import { useTranslation } from "react-i18next";
import i from "../../../assets/images/icons/i.svg";
import { PropertyCardPriceValue } from "./PropertyCardPriceValue";
import { TCardProperty } from "properties/_models/PropertyMdl";

type Props = {
    property: Pick<TCardProperty, "priceInPesos" | "features" | "price" | "priceIsNotDisplayed">;
};

export function PropertyCardPrice({ property }: Props) {
    const { t } = useTranslation();

    return (
        <div className={"flex flex-row items-center gap-1"}>
            <div className={"flex items-center justify-center"}>
                <img src={i} alt={"informations"} />
            </div>
            {property.priceIsNotDisplayed ? (
                <div>{t("propertiesPage.card.priceOnRequest")}</div>
            ) : (
                <>
                    {t("words.from")}
                    <PropertyCardPriceValue property={property} />
                </>
            )}
        </div>
    );
}

import React, { RefObject, useRef, useState } from "react";
import phone from "../../assets/images/icons/phonePrimary.svg";
import { useTranslation } from "react-i18next";
import styles from "./_css/headerContactMobile.module.css";
import clsx from "clsx";
import { Button } from "_common/ui/mui/buttons/Button";
import us from "../../assets/images/flags/usRound.svg";
import can from "../../assets/images/flags/canRound.svg";
import sharedConfig from "_configs/sharedConfig";
import mex from "../../assets/images/flags/mexRound.svg";
import { useClickOutside } from "_common/_utils/hookWindow";

export function HeaderContactMobile() {
    const { t } = useTranslation();
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const ref: RefObject<HTMLDivElement> = useRef(null);
    useClickOutside(ref, () => {
        if (menuIsOpen) setMenuIsOpen(false);
    });
    return (
        <div className={clsx("flex flex-row items-center gap-3 cursor_pointer")}>
            <img src={phone} alt={"phone"} onClick={() => setMenuIsOpen(!menuIsOpen)} className={"mr-2.5"} />
            <div
                className={clsx("position_absolute flex flex-col items-center justify-evenly", styles.container, {
                    [styles.menuIsOpen]: menuIsOpen,
                })}
                ref={ref}
            >
                <div className={styles.text}>{t("footer.clickToCall")}</div>
                <a className={styles.phones} href={"tel:" + sharedConfig.caPhone}>
                    <Button variant={"outlined"} className={styles.btn}>
                        <img src={us} alt={"us flag"} className={"mr_10"} />
                        <img src={can} alt={"canada flag"} className={"mr_10"} />
                        {sharedConfig.caPhone}
                    </Button>
                </a>
                <a className={styles.phones} href={"tel:" + sharedConfig.mexPhone}>
                    <Button variant={"outlined"} className={styles.btn}>
                        <img src={mex} alt={"mexico flag"} className={"mr_10"} />
                        {sharedConfig.mexPhone}
                    </Button>
                </a>
            </div>
        </div>
    );
}

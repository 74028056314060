import React, { PropsWithChildren } from "react";
import clsx from "clsx";
import arrow from "../../assets/images/icons/arrowExpend.svg";
import styles from "./_css/footerCollapsedTitle.module.css";

type Props = {
    title: string;
};

export function FooterCollapsedTitle(props: PropsWithChildren<Props>) {
    const [listIsDisplayed, setListIsDisplayed] = React.useState(false);
    return (
        <>
            <div
                className={clsx(styles.titleContent, "flex flex-row items-center justify-between mb_20")}
                onClick={() => setListIsDisplayed(!listIsDisplayed)}
            >
                <h3 className={styles.title}>{props.title}</h3>
                <img
                    src={arrow}
                    alt={"arrow"}
                    className={clsx(styles.arrow, { [styles.reverseArrow]: listIsDisplayed })}
                />
            </div>
            <div
                className={clsx(styles.content, {
                    [styles.isColapsed]: !listIsDisplayed,
                })}
            >
                {props.children}
            </div>
        </>
    );
}

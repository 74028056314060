import React, { CSSProperties, useState } from "react";
import clsx from "clsx";
import styles from "./_css/styles.module.css";
import { TComponent } from "pages/_models/PageMdl";

export type TextComponentProps = {
    collapse: boolean;
    value: {
        question: string;
        answer: string;
    };
    open?: boolean;
    style?: CSSProperties;
    $component?: TComponent;
};

export function QuestionComponent({ value, collapse, open, $component, ...props }: TextComponentProps) {
    const [hidden, setHidden] = useState(open);
    return (
        <div
            {...props}
            style={{ ...props.style }}
            className={clsx(styles.componentContainer, {
                [styles.componentContainerOpened]: hidden,
            })}
            itemScope
            itemProp="mainEntity"
            itemType="https://schema.org/Question"
        >
            <div
                className={clsx("flex flex-row items-center ph_20", styles.question, {
                    ["cursor_pointer"]: collapse,
                    ["p_20"]: !collapse,
                    [styles.opened]: hidden,
                })}
                onClick={() => setHidden(!hidden)}
            >
                <h3 itemProp="name">{value.question}</h3>
                {collapse && (
                    <div className="flex items-center justify-center flex-1 position_relative">
                        <div className="flex-1" />
                        <div className={styles.questionIcon}>{hidden ? "-" : "+"}</div>
                    </div>
                )}
            </div>
            <div
                className={clsx("p_20 textAlign_center", styles.answer)}
                hidden={collapse && !hidden}
                itemScope
                itemProp="acceptedAnswer"
                itemType="https://schema.org/Answer"
            >
                <div
                    itemProp="text"
                    style={{ margin: 5 }}
                    className={styles.container}
                    dangerouslySetInnerHTML={{ __html: value.answer }}
                />
            </div>
        </div>
    );
}

import React from "react";
import { TAddressMdl } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import { CardContent } from "./PropertyCardComponents";

type Props = {
    address: TAddressMdl;
};

export function PropertyCardAddress({ address }: Props) {
    const { t } = useTranslation();

    const sortAddress = [
        address.neighbourhood,
        address.city,
        t("provinces." + address.province.toUpperCase()),
        t("propertyPage.country." + (address.country ?? "MEX")),
    ];
    const completeAddress = sortAddress
        .filter((addressPart) => addressPart !== undefined && addressPart !== "")
        .slice(0)
        .join(", ");

    return <CardContent dangerouslySetInnerHTML={{ __html: completeAddress }} />;
}

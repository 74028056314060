import React, { lazy } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import styles from "../properties/propertyPage/_css/propertyOrUnitSections.module.css";
import clsx from "clsx";
import { PropertyOrUnitCta } from "properties/propertyPage/PropertyOrUnitCta";
import { useUnitStore } from "units/UnitContext";
import { UnitFeatures } from "units/UnitFeatures";
import { PropertyOrUnitHeaderMobile } from "properties/propertyPage/PropertyOrUnitHeaderMobile";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { PropertyAddress } from "properties/propertyPage/PropertyAddress";
import { UnitPrice } from "units/UnitPrice";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { NavHashLink } from "react-router-hash-link";
import { UnitDetails } from "units/UnitDetails";
import { UnitIconsInfos } from "units/UnitIconsInfos";
import { PROPERTY_SUBTYPE, PROPERTY_TYPE } from "properties/_models/PropertyMdl";
import { getTitleOfUnit } from "_common/_utils/unitUtils";
import { PropertyOrUnitInfosForm } from "properties/propertyPage/_forms/PropertyOrUnitInfosForm";
import { PropertyMapRender } from "properties/propertyPage/PropertyMapRender";
import Suspenser from "_common/loaders/Suspenser";
import { PropertyOrUnitDescription } from "properties/propertyPage/PropertyOrUnitDescription";

export enum UNIT_SECTION_TABS {
    INFO = "INFO",
    INVENTORY = "INVENTORY",
    FEATURES = "FEATURES",
    UNIT_PLAN = "UNIT_PLAN",
    // AVAILABLE_UNIT_TYPE = "AVAILABLE_UNIT_TYPE",
    FINANCING = "FINANCING",
    MAP = "MAP",
}

const LazyPropertySimilarProperties = lazy(() => import("properties/propertyPage/PropertySimilarProperties"));
const LazyUnitHistory = lazy(() => import("units/UnitHistory"));
const LazyPropertyOrUnitFloorPlans = lazy(() => import("properties/propertyPage/PropertyOrUnitFloorPlans"));
const LazyPropertyOrUnitFinancing = lazy(() => import("properties/propertyPage/PropertyOrUnitFinancing"));
const LazyPropertyInventory = lazy(() => import("properties/propertyPage/inventory/PropertyInventory"));
const LazyUnitInventory = lazy(() => import("units/UnitInventory"));

export const UnitSections = observer(() => {
    const unitStore = useUnitStore();
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();
    return (
        <div className={"mt_10"}>
            <PropertyOrUnitHeaderMobile resource={RESOURCE.UNIT_TYPES} />
            <h1 className={clsx(styles.title, "mb_0")}>{getTitleOfUnit(unitStore.unitType)}</h1>
            <div className={clsx(styles.sectionNavLinks, "flex flex-row flex-1 textAlign_center")}>
                {Object.keys(UNIT_SECTION_TABS).map((section) => {
                    return (
                        <NavHashLink
                            smooth
                            to={`#${section}`}
                            key={section}
                            onClick={() => {
                                unitStore.setSectionTab(section as UNIT_SECTION_TABS);
                            }}
                            className={clsx(styles.linkSections, "ph_5 pb_5 flex items-center justify-center", {
                                [styles.selectedSection]: unitStore.selectedSection === section,
                            })}
                        >
                            {t(`unitPage.section.${section}`)}
                        </NavHashLink>
                    );
                })}
            </div>
            <div className={clsx("flex flex-col mt_10", styles.sections)}>
                <UnitIconsInfos />
                <div id={"INFO"} className={clsx(styles.section)}>
                    <div className={styles.priceAddressContainer}>
                        <div className={clsx(styles.priceDesktop, "p_20")}>
                            <UnitPrice />
                        </div>
                        <PropertyAddress />
                    </div>
                    {propertyStore.property.type !== PROPERTY_TYPE.land && <UnitDetails />}

                    <PropertyOrUnitCta store={unitStore} />
                    <div className={"mb_40"}>
                        <PropertyOrUnitDescription
                            description={unitStore.unitType.localized?.description}
                            resource={RESOURCE.UNIT_TYPES}
                        />
                    </div>
                </div>
                <div id={"INVENTORY"}>
                    {propertyStore.property.subType === PROPERTY_SUBTYPE.buildableHouse ? (
                        <Suspenser>
                            <LazyPropertyInventory
                                customData={{
                                    bedrooms: unitStore.unitType?.bedrooms,
                                    bathrooms: unitStore.unitType?.bathrooms,
                                    price: t("propertiesPage.card.priceOnRequest"),
                                }}
                            />
                        </Suspenser>
                    ) : (
                        <Suspenser>
                            <LazyUnitInventory />
                        </Suspenser>
                    )}
                    <Suspenser>
                        <LazyUnitHistory />
                    </Suspenser>
                </div>
                <div id={"FEATURES"}>
                    <UnitFeatures />
                </div>
                <div id={"UNIT_PLAN"}>
                    <Suspenser>
                        <LazyPropertyOrUnitFloorPlans
                            propertyOrUnit={unitStore.unitType}
                            resource={RESOURCE.UNIT_TYPES}
                            store={unitStore}
                        />
                    </Suspenser>
                </div>
                <div id={"FINANCING"}>
                    <Suspenser>
                        <LazyPropertyOrUnitFinancing propertyOrUnit={propertyStore.property} />
                    </Suspenser>
                </div>
                <div id={"MAP"}>
                    <PropertyMapRender />
                </div>
                <PropertyOrUnitInfosForm resource={RESOURCE.UNIT_TYPES} />
                <Suspenser>
                    <LazyPropertySimilarProperties premium />
                    <LazyPropertySimilarProperties />
                </Suspenser>
            </div>
        </div>
    );
});

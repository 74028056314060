import { TLang } from "_configs/sharedConfig";
import { URLS } from "_configs/URLS";
import { DASHBOARD_URLS } from "users/dashboard/_configs/DASHBOARD_URLS";
import { USER_ROLES } from "users/_models/UserMdl";
import { authStore } from "users/auth/_stores/authStore";

export const MENU_ITEMS = [
    {
        key: "buy",
        itemLabelKey: "menu.buy",
        itemLink: (lang: TLang) => URLS.buy(lang),
    },
    {
        key: "aboutUs",
        itemLabelKey: "menu.aboutUs",
        itemLink: (lang: TLang) => URLS.about(lang),
    },
    {
        key: "blog",
        itemLabelKey: "menu.blog",
        itemLink: (lang: TLang) => URLS.blog(lang),
    },
    {
        key: "contactUs",
        itemLabelKey: "menu.contactUs",
        itemLink: (lang: TLang) => URLS.contact(lang),
    },
];

export const USER_MENU_ITEMS = [
    {
        key: "profile",
        itemLabelKey: "menu.profile",
        itemLink: (lang: TLang) => URLS.user.profile(lang),
    },
    {
        key: "dashboard",
        itemLabelKey: "menu.dashboard",
        itemLink: (lang: TLang) => DASHBOARD_URLS.home(lang),
        roles: [USER_ROLES.MASTER, USER_ROLES.ADMIN, USER_ROLES.DEVELOPER],
    },
    {
        key: "signOut",
        itemLabelKey: "menu.signOut",
        itemAction: () => authStore.signOut(),
    },
];

import React from "react";
import styles from "./_css/header.module.css";
import { Logo } from "_common/icons/Logo";
import clsx from "clsx";
import { HeaderContent } from "main/header/HeaderContent";
import { HeaderContentMobile } from "main/header/HeaderContentMobile";
import { usePage } from "_common/_utils/hookUtils";
import { Separator } from "_common/ui/components/Separator";
import { cn } from "_configs/twconfig";

export function Header() {
    const { isHomePage, isBuyPage } = usePage();
    console.log("isBuyPage", isBuyPage);
    return (
        <header>
            <div
                className={cn(
                    "flex flex-row items-center justify-between px-4 md:px-7 h-[var(--height-navbarNew)] bg-[var(--color-firstLvl-bg)] transition duration-500 ease-in-out fixed top-0 left-0 right-10 z-[var(--zIndex-navBar)]",
                    { absolute: isBuyPage },
                )}
            >
                <div className={styles.contentDesktop}>
                    <Logo width={210} height={65} />
                </div>
                <div className={clsx(styles.contentMobile)}>
                    <Logo width={122} height={37} />
                </div>
                <div className={styles.contentDesktop}>
                    <HeaderContent />
                </div>
                <div className={clsx(styles.contentMobile)}>
                    <HeaderContentMobile />
                </div>
            </div>
            <div className={styles.placeholder} />
            {!isHomePage && <Separator className={"mx-7 hidden lg:flex"} />}
        </header>
    );
}

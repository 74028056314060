import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, SwiperOptions } from "swiper";
import styles from "./_css/sliderLinks.module.css";
import { THomeCard } from "_common/propertyTypes/propertyTypes";
import { NavSrpsLink } from "_common/ui/links/NavSrpsLink";
import { useTranslation } from "react-i18next";
import { CardTypeOrCity } from "home/CardTypeOrCity";

type Props = {
    items: THomeCard[];
    breakPoints?: { [width: number]: SwiperOptions };
    removeGeoZone?: boolean;
};

SwiperCore.use([Autoplay, Navigation]);

export function CardTypesList(props: Props) {
    const { t } = useTranslation();
    return (
        <div id="homeCard">
            <Swiper
                loop
                navigation
                slidesPerView={1.2}
                spaceBetween={20}
                breakpoints={props.breakPoints}
                className={styles.swiperContainer}
                observeParents
                observer
            >
                {props.items.map((item, index) => {
                    if (!item.url) return null;
                    return (
                        <SwiperSlide key={index}>
                            <NavSrpsLink
                                to={item.url}
                                propertyType={item?.propertyType}
                                removeGeoZone={props.removeGeoZone}
                            >
                                <CardTypeOrCity
                                    img={item.img}
                                    text={item?.titleLabelKey ? t(item.titleLabelKey) : item.name}
                                />
                            </NavSrpsLink>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
}

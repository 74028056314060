import React from "react";

type Props = {
    videoLink?: string;
};

export function CommunitiesDiscover(props: Props) {
    function getYouTubeVideoId(url?: string) {
        if (!url) return null;
        const regExp = /^.*(youtu.be\/|v\/|watch\?v=|\/embed\/|\/watch\?v=|\/watch\?.+&v=)([^#&?]*).*/;
        const match = regExp.exec(url);

        return match && match[2].length === 11 ? match[2] : null;
    }

    return (
        <div style={{ width: "100%", height: "500px" }}>
            <iframe
                width="100%"
                height="100%"
                src={`https://www.youtube.com/embed/${getYouTubeVideoId(props.videoLink)}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
            ></iframe>
        </div>
    );
}

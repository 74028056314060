import React, { lazy, useMemo } from "react";
import { PropertyContext } from "properties/propertyPage/PropertyContext";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import dayjs from "dayjs";
import i18next from "i18next";
import { PropertyMeta } from "properties/propertyPage/PropertyMeta";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import styles from "./_css/propertyView.module.css";
import { PropertyModalInfos } from "properties/propertyPage/PropertyModalInfos";
import { PropertySections } from "properties/propertyPage/PropertySections";
import clsx from "clsx";
import Suspenser from "_common/loaders/Suspenser";
import { Helmet } from "react-helmet-async";
import { getAllAvailableUnitsOfInventory } from "_common/_utils/inventoryUtils";
import { PropertyOrUnitGallery } from "properties/gallery/PropertyOrUnitGallery";
import { PropertyOrUnitGalleryMobile } from "properties/gallery/PropertyOrUnitGalleryMobile";
import { PropertyBreadcrumb } from "properties/propertyPage/PropertyBreadcrumb";

dayjs.locale(i18next.language);

export const PROPERTY_VIEW_REF: { current: HTMLDivElement | null } = {
    current: null,
};

const LazyPropertyOrUnitSideBar = lazy(() => import("properties/propertyPage/PropertyOrUnitSideBar"));
const LazyPropertyOrUnitGalleryPage = lazy(() => import("properties/gallery/PropertyOrUnitGalleryPage"));
const LazyPropertyOrUnitGalleryImageModal = lazy(() => import("properties/gallery/PropertyOrUnitGalleryImageModal"));

type Props = {
    property: TPropertyListingMdl;
};

export function PropertyView(props: Props) {
    const propertyStore = useMemo(() => new PropertyStore(props.property), [props.property]);

    const unitsInInventory = getAllAvailableUnitsOfInventory(propertyStore.property.inventory);
    return (
        <PropertyContext propertyStore={propertyStore}>
            <Helmet>
                <link rel="preload" as="image" href={props.property?.photos?.[0]?.url} />
                <link rel="preload" as="image" href={props.property?.photos?.[0]?.thumbnail} />
                <link
                    rel="preload"
                    as="image"
                    href={props.property?.photos?.[0]?.url}
                    imagesrcset={`${props.property?.premiumPhotos?.[0]?.sm ?? props.property?.photos?.[0]?.url} 600w, ${
                        props.property?.premiumPhotos?.[0]?.md ?? props.property?.photos?.[0]?.url
                    } 960w, ${props.property?.premiumPhotos?.[0]?.lg ?? props.property?.photos?.[0]?.url} 1920w`}
                    sizes={"100vw"}
                />
            </Helmet>
            <div className={styles.mobileGallery}>
                <PropertyOrUnitGalleryMobile
                    resource={RESOURCE.PROPERTIES}
                    store={propertyStore}
                    numberOfUnitLeft={unitsInInventory.length ?? 0}
                />
            </div>
            <Suspenser noSpinner noText>
                <LazyPropertyOrUnitSideBar resource={RESOURCE.PROPERTIES} propertyOrUnit={props.property} />
            </Suspenser>
            <div className={styles.container}>
                <PropertyMeta property={props.property} />
                <div className={"flex flex-row"}>
                    <div className={clsx(styles.sectionsColumn, "flex-1")}>
                        <div className={styles.breadcrumb}>
                            <PropertyBreadcrumb />
                        </div>
                        <PropertySections />
                    </div>
                    <div ref={PROPERTY_VIEW_REF} className={clsx(styles.gallery, "flex-1")}>
                        <PropertyOrUnitGallery
                            resource={RESOURCE.PROPERTIES}
                            store={propertyStore}
                            numberOfUnitLeft={unitsInInventory.length ?? 0}
                        />
                    </div>
                </div>
            </div>
            <Suspenser>
                <LazyPropertyOrUnitGalleryPage resource={RESOURCE.PROPERTIES} />
            </Suspenser>
            <Suspenser>
                <LazyPropertyOrUnitGalleryImageModal resource={RESOURCE.PROPERTIES} store={propertyStore} />
            </Suspenser>
            <PropertyModalInfos />
        </PropertyContext>
    );
}

import React, { ReactNode } from "react";
import { userStore } from "users/_stores/userStore";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { observer } from "mobx-react";
import { TCardProperty } from "properties/_models/PropertyMdl";
import { toast } from "react-toastify";
import clsx from "clsx";
import styles from "properties/_css/propertyToFavorite.module.css";
import { TUnitTypeListingMdl } from "units/_models/UnitTypeMdl";

type ToggleToFavoriteBtnProps = {
    item: TCardProperty | TUnitTypeListingMdl;
    type: RESOURCE;
    children?: (props: { isFavorite: boolean }) => ReactNode;
    className?: string;
};

const ToFavorite = observer((props: ToggleToFavoriteBtnProps) => {
    let isFavorite = false;
    let toggleOnClick: () => void = () => {};
    switch (props.type) {
        case RESOURCE.PROPERTIES:
            isFavorite = !!userStore.favoritesProperties.find((property) => property._id === props.item._id);
            toggleOnClick = () => {
                userStore.isLogged
                    ? userStore.togglePropertyToFavorites(props.item as TCardProperty).catch((e) => {
                          toast.error(e);
                      })
                    : userStore.openSignInWithModal();
            };
            break;
        case RESOURCE.UNIT_TYPES:
            isFavorite = !!userStore.favoritesUnits.find((unit) => unit._id === props.item._id);
            toggleOnClick = () => {
                userStore.isLogged
                    ? userStore.toggleUnitsToFavorites(props.item as TUnitTypeListingMdl).catch((e) => {
                          toast.error(e);
                      })
                    : userStore.openSignInWithModal();
            };
            break;
    }
    if (!props.children || typeof props.children !== "function") return null;
    return (
        <div
            className={clsx(styles.container, "cursor_pointer", props.className)}
            onClick={(e) => {
                e.preventDefault();
                toggleOnClick();
            }}
        >
            {props.children({ isFavorite })}
        </div>
    );
});

export function ToggleToFavoriteBtn(props: ToggleToFavoriteBtnProps) {
    let loading;
    if (!userStore.isLogged) return <ToFavorite {...props} />;
    switch (props.type) {
        case RESOURCE.PROPERTIES:
            if (!userStore.favoritesPropertiesStates.isSucceeded) userStore.fetchFavoritesProperties();
            loading = userStore.favoritesPropertiesStates;
            break;
        case RESOURCE.UNIT_TYPES:
            if (!userStore.favoritesUnitsStates.isSucceeded) userStore.fetchFavoritesUnits();
            loading = userStore.favoritesUnitsStates;
            break;
    }
    if (!loading) return null;
    return (
        <LoadableFromLoading
            noSpinner
            noText
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            loading={loading}
            renderer={(_status, error) => {
                if (error) return null;
                return <ToFavorite {...props} />;
            }}
        />
    );
}

import React, { useState } from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useTranslation } from "react-i18next";
import styles from "../_css/propertyOptions.module.css";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { IconBulletPoint } from "properties/propertyPage/icons/IconBulletPoint";
import { useWindowSize } from "_common/_utils/slider";

const SEE_MORE_LIMIT = 4;

type Props = {
    type: "features" | "amenities";
};

function ListOfFeaturesOrAmenities(props: Props) {
    const { t } = useTranslation();
    const { property } = usePropertyStore();
    const { isMobile } = useWindowSize();
    const [wantsToSeeMore, setWantsToSeeMore] = useState(false);
    const isWantsToSeeMoreBtnVisible =
        isMobile && Object.keys(property[props.type]).length > SEE_MORE_LIMIT && !wantsToSeeMore;
    return (
        <div className={styles.listContainer}>
            <h2 className={clsx(styles.title, "textAlign_center")}>{t(`unit.${props.type}Title`)}</h2>
            <Grid container className={clsx(styles.itemContainer, "p_20")}>
                {Object.keys(property[props.type]).map((key, idx) => {
                    if (!property[props.type][key]) return null;
                    if (isMobile && idx > SEE_MORE_LIMIT && !wantsToSeeMore) return null;
                    return (
                        <Grid item xs={12} md={6} key={key} className={styles.itemList}>
                            <div className={"pb_2"}>
                                <IconBulletPoint />
                            </div>
                            <div className={"pb_2"}>
                                <div className={clsx(styles.item, "ml_15")}>{t(`property.${props.type}.${key}`)}</div>
                            </div>
                        </Grid>
                    );
                })}
                {isWantsToSeeMoreBtnVisible && (
                    <Grid item xs={12} className={styles.itemList}>
                        <div
                            onClick={() => setWantsToSeeMore(true)}
                            className={clsx(
                                styles.wantsToSeeMoreBtn,
                                "flex items-center justify-center textAlign_center",
                            )}
                        >
                            {t("words.seeMore")}
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

export function PropertyOptions() {
    const { property } = usePropertyStore();
    if (!property.features && !property.amenities) return null;
    return (
        <div className={clsx(styles.list, "flex flex-col")}>
            {property.amenities && <ListOfFeaturesOrAmenities type={"amenities"} />}
            {property.features && <ListOfFeaturesOrAmenities type={"features"} />}
        </div>
    );
}

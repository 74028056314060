import React, { useState } from "react";
import clsx from "clsx";
import arrowLeftCircle from "../../../assets/images/icons/arrowCircle.svg";
import styles from "./_css/arrowPaginated.module.css";

type Props = {
    numberOfSection: number;
    name: string;
    className?: string;
};

export function ArrowPaginated({ numberOfSection, className, name }: Props) {
    const [selected, setSelected] = useState(1);

    return (
        <div className={clsx("flex flex-row items-center justify-between", styles.arrowContainer, className)}>
            <div className={clsx(`swiper-button-prev ${name}`, styles.prev)}>
                <img
                    src={arrowLeftCircle}
                    alt={"arrowLeftCircle"}
                    className={styles.img}
                    onClick={() => setSelected(selected === 1 ? 1 : selected - 1)}
                />
            </div>
            <div className={styles.pagination}>{`${selected} / ${numberOfSection}`}</div>
            <div className={clsx(`swiper-button-next ${name}`, styles.next)}>
                <img
                    src={arrowLeftCircle}
                    alt={"arrowLeftCircle"}
                    className={styles.img}
                    onClick={() => setSelected(selected === numberOfSection ? numberOfSection : selected + 1)}
                />
            </div>
        </div>
    );
}

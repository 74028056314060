import React from "react";
import styles from "../_css/propertyCardPriceValue.module.css";
import { calculateAndFormatPropertyPrice } from "_common/_utils/propertyUtils";
import { NoSsr } from "@material-ui/core";
import { useCurrency } from "_common/currency/CurrencyProvider";
import { TCardProperty } from "properties/_models/PropertyMdl";

type Props = {
    property: Pick<TCardProperty, "priceInPesos" | "features" | "price">;
};

export function PropertyCardPriceValue({ property }: Props) {
    const { currentCurrency } = useCurrency();
    const price = calculateAndFormatPropertyPrice(property, currentCurrency);
    // const priceEUR = calculateAndFormatPropertyPrice(property, CURRENCY_OPTIONS.EUR);
    // const priceMXN = calculateAndFormatPropertyPrice(property, CURRENCY_OPTIONS.MXN);
    // const priceUSD = calculateAndFormatPropertyPrice(property, CURRENCY_OPTIONS.USD);
    // const priceCAD = calculateAndFormatPropertyPrice(property, CURRENCY_OPTIONS.CAD);

    return (
        <NoSsr>
            <div className={styles.container}>{price}</div>
            {/*<div className={"flex flex-col items-center"}>*/}
            {/*    <div className={styles.container}>{priceEUR}</div>*/}
            {/*    <div className={styles.container}>{priceMXN}</div>*/}
            {/*    <div className={styles.container}>{priceUSD}</div>*/}
            {/*    <div className={styles.container}>{priceCAD}</div>*/}
            {/*</div>*/}
        </NoSsr>
    );
}

import { INVENTORY_STATUS, UNIT_TYPE } from "units/_models/UnitTypeMdl";
import { PROPERTY_TYPE, TAddressMdl, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import _ from "lodash";
import { GALLERY_TABS } from "properties/gallery/PropertyOrUnitGalleryPage";
import { PROPERTY_OPTION } from "_configs/propertyConfig";
import { URLS } from "_configs/URLS";
import { reformatStringForUrls, removeAccentFromString } from "_common/_utils/alphaNumUtils";
import i18next from "i18next";
import { convertPrices, formatPrice } from "_common/_utils/currencyUtils";
import { CURRENCY_OPTIONS, PRICE_CONVERSION } from "_common/currency/CurrencyTypes";
import { TUnitListingMdl } from "admin/units/_models/UnitListingMdl";

export function getParentGeoZoneByProperty(property: { address: TAddressMdl }, lang?: string) {
    return URLS.buy(
        lang,
        property.address?.provinceShort
            ? reformatStringForUrls(property.address?.provinceShort)
            : i18next
                  .t("provinces.short." + removeAccentFromString(property.address.province).replace(" ", ""))
                  .toLowerCase(),
        reformatStringForUrls(property.address.city),
        property.address.neighbourhood ? reformatStringForUrls(property.address.neighbourhood) : undefined,
    );
}

export function getPriceBySquare(units?: (TUnitListingMdl | undefined)[]) {
    if (units && units.length) {
        const ratios = units
            .filter(
                (unit) => unit && unit.type !== UNIT_TYPE.buildableModel && unit.status === INVENTORY_STATUS.AVAILABLE,
            )
            .map((unit) => {
                if (unit) {
                    return Math.round(unit.price / unit.totalConstructArea ?? 1) / 100;
                }
            });
        const reformattedRatio = _.compact(ratios);
        if (ratios.length) {
            return Math.min(...reformattedRatio);
        }
    }
}

export function flattenObject(
    baseObject?: any,
    path = "",
    output: {
        [key: string]: string | number | undefined | null | boolean;
    } = {},
) {
    if (
        baseObject === undefined ||
        baseObject === null ||
        typeof baseObject !== "object" ||
        _.isArray(baseObject) ||
        _.isDate(baseObject)
    ) {
        output[path] = baseObject;
    } else {
        for (const key in baseObject) {
            flattenObject(baseObject[key], path !== "" ? path + "." + key : key, output);
        }
    }
    return output;
}

export function reformatImagesForGallery(item: TPropertyListingMdl) {
    if (item.photos?.length > 3 && item.photos[0].url !== item.photos[2].url) {
        const title = item?.video ? GALLERY_TABS.VIDEO : GALLERY_TABS.GALLERY;
        item.photos.splice(2, 0, { ...item.photos[0], title: title });
        if (item?.video || item.floorPlans.length > 0) {
            const title = item?.video ? GALLERY_TABS.GALLERY : GALLERY_TABS.LEVEL_PLAN;
            item.photos.splice(4, 0, { ...item.photos[0], title: title });
        }
        if (item?.video && item.floorPlans.length > 0) {
            const title = GALLERY_TABS.LEVEL_PLAN;
            item.photos.splice(6, 0, { ...item.photos[0], title: title });
        }
    }
    return item;
}

export function isLandOrCommercial(propertyType?: PROPERTY_TYPE) {
    if (!propertyType) return false;
    return propertyType === PROPERTY_TYPE.land || propertyType === PROPERTY_TYPE.commercial;
}

export function isInPesosProperty(property: Pick<TPropertyListingMdl, "features">) {
    return property?.features?.[PROPERTY_OPTION.closingInPesos];
}

export function calculateAndFormatPropertyPrice(
    property: Pick<TPropertyListingMdl, "priceInPesos" | "features" | "price">,
    currencyDisplay: CURRENCY_OPTIONS,
) {
    const minPrice = isInPesosProperty(property) ? property.priceInPesos?.min || 0 : property.price?.min || 0;
    switch (currencyDisplay) {
        case CURRENCY_OPTIONS.MXN:
            return isInPesosProperty(property)
                ? formatPrice(minPrice, false, false, CURRENCY_OPTIONS.MXN)
                : formatPrice(
                      convertPrices(minPrice, PRICE_CONVERSION.USD_TO_PESOS),
                      false,
                      false,
                      CURRENCY_OPTIONS.MXN,
                  );
        case CURRENCY_OPTIONS.USD:
            return !isInPesosProperty(property)
                ? formatPrice(minPrice)
                : formatPrice(convertPrices(minPrice, PRICE_CONVERSION.PESOS_TO_USD));
        case CURRENCY_OPTIONS.CAD:
            return formatPrice(
                convertPrices(
                    minPrice,
                    isInPesosProperty(property) ? PRICE_CONVERSION.PESOS_TO_CAD : PRICE_CONVERSION.USD_TO_CAD,
                ),
                false,
                false,
                CURRENCY_OPTIONS.CAD,
            );
        case CURRENCY_OPTIONS.EUR:
            return formatPrice(
                convertPrices(
                    minPrice,
                    isInPesosProperty(property) ? PRICE_CONVERSION.PESOS_TO_EUR : PRICE_CONVERSION.USD_TO_EUR,
                ),
                false,
                false,
                CURRENCY_OPTIONS.EUR,
            );
        default:
            return "";
    }
}

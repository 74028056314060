import React, { useState } from "react";
import styles from "./_css/menu.module.css";
import clsx from "clsx";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { TLang } from "_configs/sharedConfig";
import { NoSsr } from "@material-ui/core";
import { URLS } from "_configs/URLS";
import { userStore } from "users/_stores/userStore";
import { useTranslation } from "react-i18next";
import { MenuUser } from "main/header/menu/MenuUser";
import { Button } from "_common/ui/mui/buttons/Button";
import { TypeSubMenu } from "main/header/menu/TypeSubMenu";
import { MENU_ITEMS } from "main/header/menu/MENU_ITEMS";

export function Menu() {
    const { t } = useTranslation();
    const [menuKeyHovered, setMenuKeyHovered] = useState<string | undefined>(undefined);

    const IsLogged = __BROWSER__ && userStore.isLogged;
    return (
        <nav>
            <ul className={clsx(styles.menu, "flex flex-row items-center")}>
                {MENU_ITEMS.map((menuItem) => {
                    const isPurposeMenu = menuItem.key === "buy";
                    return (
                        <li
                            key={menuItem.key}
                            className={clsx(
                                "pr_30 flex items-center justify-center position_relative",
                                styles.menuItem,
                            )}
                            onMouseEnter={() => {
                                setMenuKeyHovered(menuItem.key);
                            }}
                            onMouseLeave={() => setMenuKeyHovered(undefined)}
                        >
                            <Link to={menuItem.itemLink(i18next.language as TLang)}>{t(menuItem.itemLabelKey)}</Link>
                            {isPurposeMenu && (
                                <div
                                    className={clsx(styles.subMenuContainer, {
                                        ["hidden"]: menuKeyHovered !== menuItem.key,
                                    })}
                                >
                                    <TypeSubMenu />
                                </div>
                            )}
                        </li>
                    );
                })}
                <NoSsr
                    fallback={
                        <li key={"signIn"} className={clsx(styles.signIn, "")}>
                            <Link to={URLS.auth.signIn()}>
                                <Button variant={"outlined"}>{t("menu.signIn")}</Button>
                            </Link>
                        </li>
                    }
                >
                    {IsLogged ? (
                        <li
                            onMouseEnter={() => setMenuKeyHovered("profil")}
                            onMouseLeave={() => setMenuKeyHovered(undefined)}
                            key={"profile"}
                            className={clsx("ph_10 position_relative ", styles.menuItem)}
                        >
                            <Link to={URLS.user.profile()}>{t("menu.profile")}</Link>
                            <div
                                className={clsx(styles.subMenuContainer, {
                                    ["hidden"]: menuKeyHovered !== "profil",
                                })}
                            >
                                <MenuUser />
                            </div>
                        </li>
                    ) : (
                        <li key={"signIn"}>
                            <Link to={URLS.auth.signIn()}>
                                <Button variant={"outlined"}>{t("menu.signIn")}</Button>
                            </Link>
                        </li>
                    )}
                </NoSsr>
            </ul>
        </nav>
    );
}

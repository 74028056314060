import React from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/propertyIconsInfos.module.css";
import { IconLand } from "properties/propertyPage/icons/IconLand";

export function PropertyLandIconsInfos() {
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container, "flex flex-row mv_40")}>
            <div className={clsx(styles.iconContainer, "flex flex-col items-center")}>
                <IconLand size={60} />
                <span className={"mt_10 textAlign_center"}>
                    {t("propertyPage.propertyType")} {t("property.types." + propertyStore.property.type)}
                </span>
            </div>
        </div>
    );
}

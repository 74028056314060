import React, { useState } from "react";
import phone from "../../assets/images/icons/phonePrimary.svg";
import { useTranslation } from "react-i18next";
import styles from "./_css/headerContact.module.css";
import us from "../../assets/images/flags/usRound.svg";
import can from "../../assets/images/flags/canRound.svg";
import mex from "../../assets/images/flags/mexRound.svg";
import { IconComponent } from "components/base/icon/IconComponent";
import sharedConfig from "_configs/sharedConfig";
import clsx from "clsx";

export function HeaderContact() {
    const { t } = useTranslation();
    const [isDisplayed, setIsDisplayed] = useState(false);
    return (
        <div
            className={clsx("flex flex-row items-center justify-end mb_10 gap-3", {
                ["cursor_pointer"]: !isDisplayed,
            })}
            onClick={() => {
                if (!isDisplayed) setIsDisplayed(true);
            }}
        >
            <img src={phone} alt={"phone"} className={clsx({ mr_10: isDisplayed })} />
            {!isDisplayed && <div className={styles.text}>{t("footer.callUs")}</div>}
            {isDisplayed && (
                <>
                    <img src={us} alt={"us flag"} />
                    <img src={can} alt={"canada flag"} />
                    <a className={styles.phones} href={"tel:" + sharedConfig.caPhone}>
                        {sharedConfig.caPhone}
                    </a>
                    <img src={mex} alt={"mexico flag"} />
                    <a className={styles.phones} href={"tel:" + sharedConfig.mexPhone}>
                        {sharedConfig.mexPhone}
                    </a>
                    <div className={"cursor_pointer flex items-center justify-center"}>
                        <IconComponent icon={"close"} onClick={() => setIsDisplayed(false)} />
                    </div>
                </>
            )}
        </div>
    );
}

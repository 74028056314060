import React from "react";
import arrow from "../assets/images/rightArrowTinyPrimary.svg";
import { useTranslation } from "react-i18next";

type Props = {
    img?: string;
    text: string;
};

export function CardTypeOrCity({ img, text }: Props) {
    const { t } = useTranslation();
    return (
        <div
            className={
                "flex flex-col items-center h-[180px] shadow-[0_0_5px_rgba(26,58,69,0.2)] hover:shadow-[0_0_10px_0_rgba(26,58,69,0.3)] group"
            }
        >
            {!!img && <img src={img} alt={text} className={"w-full h-[120px] object-cover"} />}
            <div className={"flex flex-row items-center justify-between p-4 h-full w-full"}>
                <div>{text}</div>
                <div className={"text-xs flex items-center gap-3 group-hover:translate-x-1 transition-all"}>
                    {t("homePage.viewAll")}
                    <img src={arrow} alt={"arrow"} />
                </div>
            </div>
        </div>
    );
}

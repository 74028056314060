import React from "react";
import { Dialog } from "_common/ui/dialogs/Dialog";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import styles from "./_css/propertyOrUnitGalleryPage.module.css";
import clsx from "clsx";
import { GalleryProperty } from "gallery/GalleryProperty";
import { VideoComponent } from "components/base/video/VideoComponent";
import { PropertyGalleryTab } from "properties/gallery/PropertyGalleryTab";
import { RESOURCE, RESOURCE_KEY } from "admin/_common/photos/AdminPhotosStore";
import "./_css/propertyOrUnitGalleryPage.css";
import { PropertyOrUnitGalleryAndDocuments } from "properties/propertyPage/PropertyOrUnitGalleryAndDocuments";
import { CompareBtn } from "compare/CompareBtn";
import { ToggleToFavoriteBtn } from "properties/ToggleToFavoriteBtn";
import { PropertyGalleryCta } from "properties/propertyPage/PropertyGalleryCta";
import { formatPesosPrice, formatPrice } from "_common/_utils/currencyUtils";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { TUnitListingMdl } from "units/_models/UnitTypeMdl";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { isInPesosProperty } from "_common/_utils/propertyUtils";
import { PropertyGalleyConstructProgress } from "properties/gallery/PropertyGalleyConstructProgress";
import { Tour3D } from "components/base/tour3D/Tour3D";
import { useUnitStore } from "units/UnitContext";
import { VideosGalleryProperty } from "gallery/videosGalleryProperty";

export enum GALLERY_TABS {
    GALLERY = "GALLERY",
    TOUR_3D = "TOUR_3D",
    VIDEO = "VIDEO",
    LEVEL_PLAN = "LEVEL_PLAN",
    CONSTRUCT_PROGRESS = "CONSTRUCT_PROGRESS",
    SHOWROOM = "SHOWROOM",
    PREVIOUS_PROJECTS = "PREVIOUS_PROJECTS",
    FINISHINGS = "FINISHINGS",
    FURNITURES = "FURNITURES",
}

type Props = {
    resource: RESOURCE;
};

export default observer((props: Props) => {
    const propertyStore = usePropertyStore();
    const unitStore = useUnitStore();
    const { t } = useTranslation();
    const store = props.resource === RESOURCE.PROPERTIES ? propertyStore : unitStore;
    const item = props.resource === RESOURCE.PROPERTIES ? propertyStore.property : unitStore.unitType;
    const isConstructProgress =
        propertyStore.property?.constructProgress && propertyStore.property?.constructProgress.length > 0;

    const isPreviousProjects =
        (!!propertyStore.property?.previousProjects && propertyStore.property?.previousProjects.length > 0) ||
        (!!propertyStore.property?.previousProjectsVideos && propertyStore.property?.previousProjectsVideos.length > 0);

    return (
        <Dialog
            open={store.isGalleryOpened > -1}
            onClose={() => store.closeGallery()}
            maxWidth={"lg"}
            fullScreen
            closeCross
            isTransparentBg
            closeSize={"default"}
            className={styles.dialog}
            withoutPlaceholder
            id={"propertyPageId"}
        >
            <div className={styles.container}>
                {props.resource === RESOURCE.PROPERTIES ? (
                    <h2 className={styles.header}>
                        {item.localized.title}
                        {!propertyStore.property.priceIsNotDisplayed && (
                            <span>{` | ${t("propertyPage.from")} ${formatPrice(item.price?.min)}`}</span>
                        )}
                    </h2>
                ) : (
                    <h2 className={styles.header}>
                        {(item as TUnitListingMdl).bedrooms === 0
                            ? t(`unit.type.${(item as TUnitListingMdl).type}Studio`)
                            : t(`unit.type.${(item as TUnitListingMdl).type}`, {
                                  count: (item as TUnitListingMdl).bedrooms,
                              }) +
                              ((item as TUnitListingMdl)?.variant ? ` - ${(item as TUnitListingMdl).variant}` : "")}
                    </h2>
                )}
                <div className={"position_relative flex flex-row items-center"}>
                    <div className={clsx(styles.window, "flex-1")}>
                        <div className={clsx("flex flex-row items-center textAlign_center mv_10 ml_10", styles.tabs)}>
                            {Object.keys(GALLERY_TABS).map((type) => {
                                switch (type) {
                                    case GALLERY_TABS.GALLERY:
                                        if (item.photos && item.photos.length < 1) return null;
                                        break;
                                    case GALLERY_TABS.TOUR_3D:
                                        if (!item.visit3D) return null;
                                        break;
                                    case GALLERY_TABS.VIDEO:
                                        if (!item.video) return null;
                                        break;
                                    case GALLERY_TABS.LEVEL_PLAN:
                                        if (item.floorPlans && item.floorPlans.length < 1) return null;
                                        break;
                                    case GALLERY_TABS.SHOWROOM:
                                        if (item.showroom && item.showroom.length < 1) return null;
                                        break;
                                    case GALLERY_TABS.CONSTRUCT_PROGRESS:
                                        if (!isConstructProgress) {
                                            return null;
                                        }
                                        break;
                                    case GALLERY_TABS.FINISHINGS:
                                        if (
                                            props.resource == RESOURCE.UNIT_TYPES ||
                                            (propertyStore?.property?.finishings &&
                                                propertyStore?.property?.finishings.length < 1)
                                        ) {
                                            return null;
                                        }
                                        break;
                                    case GALLERY_TABS.PREVIOUS_PROJECTS:
                                        if (
                                            (propertyStore?.property?.previousProjectsVideos &&
                                                propertyStore?.property?.previousProjectsVideos.length < 1 &&
                                                propertyStore?.property?.previousProjects &&
                                                propertyStore?.property?.previousProjects.length < 1) ||
                                            !isPreviousProjects
                                        ) {
                                            return null;
                                        }
                                        break;
                                    case GALLERY_TABS.FURNITURES:
                                        if (
                                            props.resource === RESOURCE.PROPERTIES ||
                                            !unitStore.unitType.furnitures ||
                                            !unitStore.unitType.furnitures.length
                                        ) {
                                            return null;
                                        }
                                        break;
                                }
                                const isGalleryTabsLevelPlanAndIsUnit =
                                    type === GALLERY_TABS.LEVEL_PLAN && props.resource == RESOURCE.UNIT_TYPES;
                                return (
                                    <div
                                        onClick={() => {
                                            store.setOpenedGalleryTab(type as GALLERY_TABS);
                                        }}
                                        key={type}
                                        className={clsx(styles.tab, "flex items-center justify-center", {
                                            [styles.selectedTab]: type === store.openedGalleryTab,
                                        })}
                                    >
                                        {t(
                                            isGalleryTabsLevelPlanAndIsUnit
                                                ? `propertyPage.gallery.tabs.UNIT_LEVEL_PLAN`
                                                : `propertyPage.gallery.tabs.${type}`,
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                        <div className={clsx("flex flex-row", styles.content)}>
                            <div className={clsx("flex-2", styles.galleries)}>
                                <PropertyGalleryTab hidden={store.openedGalleryTab !== GALLERY_TABS.GALLERY}>
                                    <GalleryProperty
                                        resource={props.resource}
                                        store={store}
                                        imageResource={RESOURCE_KEY.PHOTOS}
                                    />
                                </PropertyGalleryTab>
                                {!!item.visit3D && (
                                    <PropertyGalleryTab hidden={store.openedGalleryTab !== GALLERY_TABS.TOUR_3D}>
                                        <Tour3D embedSrc={propertyStore.property.visit3D} />
                                    </PropertyGalleryTab>
                                )}
                                {!!item.video && (
                                    <PropertyGalleryTab hidden={store.openedGalleryTab !== GALLERY_TABS.VIDEO}>
                                        <VideoComponent url={item.video} size={"100%"} />
                                    </PropertyGalleryTab>
                                )}
                                {!!item?.floorPlans && item?.floorPlans.length > 0 && (
                                    <PropertyGalleryTab hidden={store.openedGalleryTab !== GALLERY_TABS.LEVEL_PLAN}>
                                        <PropertyOrUnitGalleryAndDocuments
                                            resource={props.resource}
                                            store={store}
                                            resourceKey={RESOURCE_KEY.FLOOR_PLANS}
                                        />
                                    </PropertyGalleryTab>
                                )}
                                {!!item?.showroom && item?.showroom.length > 0 && (
                                    <PropertyGalleryTab hidden={store.openedGalleryTab !== GALLERY_TABS.SHOWROOM}>
                                        <GalleryProperty
                                            resource={props.resource}
                                            store={store}
                                            imageResource={RESOURCE_KEY.SHOWROOM}
                                        />
                                    </PropertyGalleryTab>
                                )}
                                {isConstructProgress && (
                                    <PropertyGalleryTab
                                        hidden={store.openedGalleryTab !== GALLERY_TABS.CONSTRUCT_PROGRESS}
                                    >
                                        <PropertyGalleyConstructProgress
                                            constructProgress={propertyStore.property.constructProgress}
                                        />
                                    </PropertyGalleryTab>
                                )}
                                {isPreviousProjects && (
                                    <PropertyGalleryTab
                                        hidden={store.openedGalleryTab !== GALLERY_TABS.PREVIOUS_PROJECTS}
                                    >
                                        <GalleryProperty
                                            resource={RESOURCE.PROPERTIES}
                                            store={propertyStore}
                                            imageResource={RESOURCE_KEY.PREVIOUS_PROJECTS}
                                        />
                                        <VideosGalleryProperty />
                                    </PropertyGalleryTab>
                                )}
                                {!!propertyStore.property?.finishings && propertyStore.property?.finishings.length > 0 && (
                                    <PropertyGalleryTab hidden={store.openedGalleryTab !== GALLERY_TABS.FINISHINGS}>
                                        <PropertyOrUnitGalleryAndDocuments
                                            resource={RESOURCE.PROPERTIES}
                                            store={store}
                                            resourceKey={RESOURCE_KEY.FINISHINGS}
                                        />
                                    </PropertyGalleryTab>
                                )}
                                {!!unitStore.unitType?.furnitures && unitStore.unitType?.furnitures.length > 0 && (
                                    <PropertyGalleryTab hidden={store.openedGalleryTab !== GALLERY_TABS.FURNITURES}>
                                        <PropertyOrUnitGalleryAndDocuments
                                            resource={RESOURCE.UNIT_TYPES}
                                            store={store}
                                            resourceKey={RESOURCE_KEY.FURNITURES}
                                        />
                                    </PropertyGalleryTab>
                                )}
                            </div>
                            <div className={clsx("flex-1", styles.cta)}>
                                <PropertyGalleryCta />
                            </div>
                        </div>
                    </div>
                    <div className={clsx("flex flex-col", styles.iconContainer)}>
                        <div className={clsx("flex flex-col mt_20", styles.shareIcons)}>
                            <CompareBtn
                                type={RESOURCE.PROPERTIES}
                                id={item._id}
                                label={t("words.compare")}
                                isItemPage
                                button
                                variant={"outlined"}
                                color={"white"}
                                noShadow
                                direction={"row"}
                            />
                            <ToggleToFavoriteBtn
                                item={item}
                                type={RESOURCE.PROPERTIES}
                                label={t("words.favorite")}
                                button
                                variant={"outlined"}
                                color={"white"}
                                noShadow
                                direction={"row"}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.footerContainer}>
                    <div className={clsx(styles.ctaMobile, "flex flex-row items-center p_10")}>
                        {propertyStore.property.priceIsNotDisplayed ? (
                            <div className={styles.price}>{t("propertiesPage.card.priceOnRequest")}</div>
                        ) : (
                            <div className={"flex flex-col"}>
                                <div className={styles.price}>{formatPrice(item.price.min)}</div>
                                {props.resource === RESOURCE.PROPERTIES &&
                                    isInPesosProperty(item as TPropertyListingMdl) && (
                                        <div className={styles.price}>
                                            ({formatPesosPrice((item as TPropertyListingMdl).priceInPesos?.min ?? 0)})
                                        </div>
                                    )}
                            </div>
                        )}
                        <div className={"flex-1"} />
                        <CompareBtn type={RESOURCE.PROPERTIES} id={item._id} isItemPage color={"white"} size={"28"} />
                        <ToggleToFavoriteBtn item={item} type={RESOURCE.PROPERTIES} color={"white"} size={"28"} />
                    </div>
                    <div className={clsx(styles.footer, "flex items-center justify-center")}>
                        <UiButton
                            className={styles.btn}
                            color={"secondary"}
                            variant={"contained"}
                            onClick={() => store.setInfosModalOpen()}
                        >
                            {t("propertyPage.cta.info")}
                        </UiButton>
                    </div>
                </div>
            </div>
        </Dialog>
    );
});

import { SwiperOptions } from "swiper";

export enum MAP_OR_LIST_SELECTED {
    "LIST" = "list",
    "MAP" = "map",
}

export const BREAKPOINTS_SECTION_CARDS: {
    [key in MAP_OR_LIST_SELECTED]: { [key: number]: SwiperOptions };
} = {
    [MAP_OR_LIST_SELECTED.LIST]: {
        500: {
            slidesPerView: 1.5,
        },
        600: {
            slidesPerView: 2,
        },
        750: {
            slidesPerView: 3,
        },
        900: {
            slidesPerView: 3.5,
        },
        1280: {
            slidesPerView: 5,
        },
    },
    [MAP_OR_LIST_SELECTED.MAP]: {
        500: {
            slidesPerView: 1.5,
        },
        600: {
            slidesPerView: 2,
        },
        750: {
            slidesPerView: 2.5,
        },
        900: {
            slidesPerView: 2.5,
        },
        1280: {
            slidesPerView: 3,
        },
    },
};

import React from "react";
import { TCardProperty } from "properties/_models/PropertyMdl";
import { CardContent, CardTitle } from "properties/propertyCard/components/PropertyCardComponents";
import { PropertyCardTitle } from "properties/propertyCard/components/PropertyCardTitle";
import { PropertyCardSubTitle } from "properties/propertyCard/components/PropertyCardSubTitle";
import { isLandOrCommercial } from "_common/_utils/propertyUtils";
import { PropertyCardBeds } from "properties/propertyCard/components/PropertyCardBeds";
import { PropertyCardSurfaces } from "properties/propertyCard/components/PropertyCardSurfaces";
import { PropertyCardAddress } from "properties/propertyCard/components/PropertyCardAddress";
import { PropertyCardPrice } from "properties/propertyCard/components/PropertyCardPrice";

type Props = {
    property: TCardProperty;
};

export function PropertyCardInfos({ property }: Props) {
    return (
        <>
            <div className={"mb-4"}>
                <CardTitle>
                    <PropertyCardTitle title={property.localized.title} />
                </CardTitle>
                <CardTitle>
                    <PropertyCardSubTitle property={property} />
                </CardTitle>
            </div>
            <div className={"mb-4"}>
                <CardContent className={"mb-1 gap-1"}>
                    {!isLandOrCommercial(property.type) && typeof property?.bedrooms?.min === "number" && (
                        <PropertyCardBeds min={property.bedrooms.min} max={property.bedrooms?.max} />
                    )}
                    {property?.squareSurface?.min && <PropertyCardSurfaces surfaceMin={property.squareSurface.min} />}
                </CardContent>
                <PropertyCardAddress address={property.address} />
            </div>
            <PropertyCardPrice property={property} />
        </>
    );
}

import React from "react";
import { CommunitiesTitle } from "communities/components/CommunitiesTitle";
import { useCommunityStore } from "communities/CommunityContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";

const NAME = "communitiesUsefulFacts";

export function CommunitiesUsefulFacts() {
    const communityStore = useCommunityStore();
    const { t } = useTranslation();

    return (
        <div className={"my-10"}>
            <CommunitiesTitle title={t("communitiesPage.usefulFacts")} delimiter={1} />
            <div id={NAME} className={"relative"}>
                <Swiper
                    loop
                    navigation={{
                        prevEl: `.swiper-button-prev.${NAME}`,
                        nextEl: `.swiper-button-next.${NAME}`,
                    }}
                    slidesPerView={3}
                    spaceBetween={20}
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                        },
                        690: {
                            slidesPerView: 1.5,
                        },
                        1180: {
                            slidesPerView: 2.5,
                        },
                        1400: {
                            slidesOffsetBefore: 10,
                            slidesPerView: 3,
                        },
                    }}
                >
                    {communityStore.community.facts.map((fact, index) => (
                        <SwiperSlide key={index}>
                            <div
                                className={
                                    "flex flex-col items-stretch p-5 gap-5 border border-[var(--color-secondary)] rounded h-[300px] "
                                }
                            >
                                <p className={"font-bold"}>{fact.title}</p>
                                <p className={"overflow-auto"}>{fact.description}</p>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className={`swiper-button-prev ${NAME}`} />
                <div className={`swiper-button-next ${NAME}`} />
            </div>
        </div>
    );
}

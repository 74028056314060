import React from "react";
import clsx from "clsx";
import styles from "properties/propertyPage/_css/propertyOrUnitInfosForm.module.css";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { userStore } from "users/_stores/userStore";
import { useTranslation } from "react-i18next";
import { TAskForVisitOrInfoForm } from "properties/propertyPage/PropertyGalleryCta";
import { Input } from "_common/ui/forms/Input";
import { TextArea } from "_common/ui/forms/TextArea";
import { PropertyFormBtn } from "properties/propertyPage/PropertyFormBtn";
import { InputPhone } from "_common/ui/forms/InputPhone";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { HiddenFieldsAttributerForm } from "components/form/HiddenFieldsAttributerForm";

type Props = {
    resource: RESOURCE;
    IsInModal?: boolean;
};

export function PropertyOrUnitInfosForm(props: Props) {
    const form = useForm<TAskForVisitOrInfoForm>({
        mode: "onBlur",
        defaultValues: {
            name: userStore.isLogged ? userStore.user?.firstName + " " + userStore.user?.lastName : "",
            phone: userStore.isLogged ? userStore.user?.phone : "",
            email: userStore.isLogged ? userStore.user?.email : "",
            subject: "",
            message: "",
        },
    });
    const { t } = useTranslation();
    return (
        <FormProvider {...form}>
            <div className={clsx("m_20 p_40 pb_20", styles.container, { [styles.isInModal]: props.IsInModal })}>
                <h3 className={clsx(styles.title, "textAlign_center mb_25")}>{t("propertyPage.cta.learn")}</h3>
                <div className={clsx(styles.content, "flex flex-row")}>
                    <div className={clsx(styles.infos, "flex flex-col flex-1")}>
                        <div>
                            <Controller
                                as={Input}
                                name="name"
                                control={form.control}
                                className={styles.input}
                                placeholder={t("contactPage.form.name")}
                            />
                        </div>
                        <div>
                            <Controller
                                as={Input}
                                name="email"
                                control={form.control}
                                className={styles.input}
                                placeholder={t("contactPage.form.email")}
                            />
                        </div>
                        <div>
                            <Controller
                                as={InputPhone}
                                name="phone"
                                control={form.control}
                                className={styles.input}
                                placeholder={t("contactPage.form.phone")}
                            />
                        </div>
                        <div>
                            <Controller
                                as={Input}
                                name="subject"
                                control={form.control}
                                className={styles.input}
                                placeholder={t("contactPage.form.subject")}
                            />
                        </div>
                    </div>
                    <div className={"flex-1"}>
                        <Controller
                            as={TextArea}
                            name="message"
                            control={form.control}
                            rows={5}
                            placeholder={"Message"}
                            className={clsx(styles.area)}
                        />
                    </div>
                </div>
                <div className={clsx("flex flex-col items-center", styles.footer)}>
                    <PropertyFormBtn resource={props.resource} />
                </div>
                <div className={clsx(styles.text, "mt_20 mb_20")}>{t("propertyPage.cta.rules")}</div>
            </div>
            <HiddenFieldsAttributerForm />
        </FormProvider>
    );
}

import React from "react";
import { IPageMdl } from "pages/_models/PageMdl";
import i18next from "i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

import clsx from "clsx";
import styles from "./_css/articleSearchCard.module.css";
import { observer } from "mobx-react";

type Props = {
    blog: IPageMdl;
    isArticlePage?: boolean;
};

export const ArticleSearchCard = observer((props: Props) => {
    const lang = i18next.language;
    return (
        <Link
            to={"/" + lang + props.blog.localized[i18next.language].url}
            className={clsx("pl_20 pv_20 flex flex-row items-center", styles.container)}
        >
            <div
                className={clsx({
                    "flex-4": !props.isArticlePage,
                    "flex-3": props.isArticlePage,
                    "text-sm": props.isArticlePage,
                })}
            >
                {props.blog.localized[i18next.language].title}
            </div>
            <div
                className={clsx("flex flex-row justify-end", {
                    "flex-1": !props.isArticlePage,
                    "flex-2": props.isArticlePage,
                })}
            >
                <LazyLoadImage className={styles.image} src={props.blog.localized[i18next.language].image} />
            </div>
        </Link>
    );
});

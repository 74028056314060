import React from "react";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useTranslation } from "react-i18next";
import { PHASE_STATUS } from "admin/phases/_models/PhasesMdl";
import dayjs from "dayjs";
import clsx from "clsx";
import styles from "./_css/phaseLabel.module.css";
import { INVENTORY_STATUS } from "units/_models/UnitTypeMdl";
import { useUnitStore } from "units/UnitContext";

export function PhaseLabelForUnit() {
    const propertyStore = usePropertyStore();
    const unitStore = useUnitStore();
    const { t } = useTranslation();
    if (propertyStore.property?.inventory && propertyStore.property?.inventory[0]?.buildings.length < 1) return null;

    const status: INVENTORY_STATUS = unitStore.unitType.status;

    return (
        <div className={clsx("flex flex-col p_20", styles.container)}>
            {propertyStore.property.inventory.length > 0 && (
                <div>
                    {propertyStore.property.inventory
                        .sort((a, b) =>
                            a?.phase?.deliveryDate &&
                            b?.phase?.deliveryDate &&
                            dayjs(a.phase.deliveryDate) < dayjs(b.phase.deliveryDate)
                                ? -1
                                : 1,
                        )
                        .map((phase, idx) => {
                            if (!phase || phase.buildings.length === 0) return null;
                            const statusForPhase = propertyStore.property?.statusByPhase?.find(
                                (statusByPhase) => statusByPhase.deliveryDate === phase.phase.deliveryDate,
                            );
                            return (
                                <div className={"flex flex-row"} key={idx}>
                                    {statusForPhase && status === INVENTORY_STATUS.AVAILABLE && (
                                        <div className={"mr_10"}>
                                            {t("propertyPage.status." + statusForPhase.status) + " :"}
                                        </div>
                                    )}
                                    <div className={"mr_10"}>{t("propertyPage.phase", { count: idx + 1 })}</div>
                                    {phase.phase.status === PHASE_STATUS.AVAILABLE_X_MONTHS_AFTER ? (
                                        <div>
                                            {t(`propertyPage.AVAILABLE_X_MONTHS_AFTER_TEXT`, {
                                                monthsNumber: phase.phase.monthsNumber,
                                            })}
                                        </div>
                                    ) : phase.phase.status !== PHASE_STATUS.UNDER_CONSTRUCTION ? (
                                        <div>{t(`propertyPage.${phase.phase.status}`)}</div>
                                    ) : phase.phase.deliveryDate ? (
                                        <div>{dayjs(phase.phase.deliveryDate).format("MMM YY").toString()}</div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            );
                        })}
                </div>
            )}
        </div>
    );
}

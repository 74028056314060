import React, { lazy } from "react";
import hero from "../assets/images/homePage/hero.webp";
import heroMobile from "../assets/images/homePage/hero.webp";
import googleRate from "../assets/images/networks/googleRate.svg";
import { useTranslation } from "react-i18next";
import Suspenser from "_common/loaders/Suspenser";

const LazySearchBar = lazy(() => import("../properties/searchBar/SearchBarGeoZoneAndProperties"));

export function HeaderBlock() {
    const { t } = useTranslation();
    return (
        <div className="mx-2.5 lg:mx-[30px] h-[400px] md:h-[620px] rounded-lg position_relative flex items-center justify-center p_10">
            <img
                src={hero}
                alt={"house image"}
                className="z-[-1] absolute inset-0 object-cover h-full w-full rounded-lg desktopDisplay"
            />
            <img
                src={heroMobile}
                alt={"house image"}
                className="z-[-1] absolute inset-0 object-cover h-full w-full rounded-lg mobileDisplay"
            />
            <img
                src={googleRate}
                alt={"Google rate"}
                className="absolute bottom-[30px] left-1/2 transform -translate-x-1/2 h-5 md:left-10 md:translate-x-0 md:h-auto"
            />
            <div className={"flex flex-col items-center mb_40"}>
                <div className="font-semibold text-white text-center leading-[1.1] mb-2.5 px-5 text-[32px] lg:text-[42px]">
                    {t("homePage.headerBlock.title")}
                </div>
                <div className="font-semibold text-white text-center text-base lg:text-xl md:!mb-10 ph_20 mb_20">
                    {t("homePage.headerBlock.subTitle")}
                </div>
                <div className="w-full max-w-[600px] h-[62px]">
                    <Suspenser fallback={<div className="w-full max-w-[600px] h-[62px]" />}>
                        <LazySearchBar />
                    </Suspenser>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import { CommunitiesSectionCard } from "communities/components/CommunitiesSectionCard";
import { CommunitiesTitle } from "communities/components/CommunitiesTitle";
import { useCommunityStore } from "communities/CommunityContext";
import { useTranslation } from "react-i18next";

export function CommunitiesInformationSection() {
    const { community } = useCommunityStore();
    const { t } = useTranslation();

    return (
        <div className={"flex flex-col justify-between lg:flex-row lg:items-start"}>
            <div className={"flex-1"}>
                <h2 className={"colorSecondary"}>{t("communitiesPage.community")}:</h2>
                <CommunitiesTitle
                    title={`${community.localized.title} in ${community.geoZone.address.city}`}
                    delimiter={community.localized.title ? community.localized.title.split(" ").length : 0}
                />
                <div className={" lg:flex justify-between"}>
                    <p className={" pr-28 flex-1"}>{community.localized.description}</p>

                    <CommunitiesSectionCard />
                </div>
            </div>
        </div>
    );
}

import { useState } from "react";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { propertiesStore } from "properties/_stores/propertiesStore";
import { unitsStore } from "units/_stores/unitTypesStore";
import { useLoadingFromPromise } from "_common/loaders/useLoadingFromPromise";
import { useParams } from "react-router";
import { MEXICO_CITIES } from "_common/regions/regions";
import slugify from "slugify";
import { reformatStringForUrls, reformatUrlForOriginalName, reformatUtf8Url } from "_common/_utils/alphaNumUtils";
import { useLocation } from "react-router-dom";
import sharedConfig from "_configs/sharedConfig";
import { URLS } from "_configs/URLS";
import {
    getMapCoordinatesFromParams,
    PARAMS_BEACHFRONT_OR_LUXURY_PREFIX,
    PARAMS_NORTH_PREFIX,
    PARAMS_PAGE_PREFIX,
    PARAMS_ZOOM_PREFIX,
} from "_common/_utils/searchUtils";
import { getI18nExpByLang } from "_common/_utils/pageUtils";
import { useTranslation } from "react-i18next";
import { isPropertyType } from "_common/_utils/filterUtils";
import { usePropertiesStore } from "properties/PropertiesPageContext";

export function usePropertyOrUnit(type: RESOURCE, itemId: string) {
    const itemOrFetch = RESOURCE.PROPERTIES === type ? propertiesStore.get(itemId) : unitsStore.get(itemId);
    const { loading } = useLoadingFromPromise(
        "_id" in itemOrFetch ? new Promise((resolve) => resolve(itemOrFetch)) : itemOrFetch,
    );

    if ("_id" in itemOrFetch) {
        return { itemOrFetch, loading: undefined };
    }
    return { itemOrFetch, loading };
}

export function useSearchPageParams() {
    // === IS_GEO_ZONE ===
    // /region(p1)/city(p2)/neighbourhood(p3)/beachFrontOrLuxuryOrTypeOrCommunities(p5)/zoom(p6)
    // /region(p1)/city(p2)/beachFrontOrLuxuryOrTypeOrCommunities(p5)/zoom(p6)
    // /region(p1)/city(p2)/neighbourhood(p3)/propertyType(p4)/page(p5)/zoom(p6)
    // /region(p1)/city(p2)/propertyType(p3)/beachFrontOrLuxury(p4)/page(p5)/zoom(p6)
    // /region(p1)/city(p2)/neighbourhood(p3)/propertyType(p4)/page(p5)
    // /region(p1)/city(p2)/neighbourhood(p3)/propertyType(p4)/zoom(p5)
    // /region(p1)/city(p2)/propertyType(p3)/beachFrontOrLuxury(p4)/page(p5)
    // /region(p1)/city(p2)/propertyType(p3)/beachFrontOrLuxury(p4)/zoom(p5)
    // /region(p1)/city(p2)/propertyType(p3)/page(p4)/zoom(p5)
    // /region(p1)/city(p2)/neighbourhood(p3)/page(p4)/zoom(p5)
    // /region(p1)/city(p2)/beachFrontOrLuxury(p3)/page(p4)/zoom(p5)
    // /region(p1)/city(p2)/neighbourhood(p3)/propertyType(p4)
    // /region(p1)/city(p2)/beachFrontOrLuxury(p4)
    // /region(p1)/city(p2)/neighbourhood(p3)/page(p4)
    // /region(p1)/city(p2)/neighbourhood(p3)/zoom(p4)
    // /region(p1)/city(p2)/propertyType(p3)/page(p4)
    // /region(p1)/city(p2)/propertyType(p3)/zoom(p4)
    // /region(p1)/city(p2)/beachFrontOrLuxury(p3)/page(p4)
    // /region(p1)/city(p2)/beachFrontOrLuxury(p3)/zoom(p4)
    // /region(p1)/city(p2)/page(p3)/zoom(p4)
    // /region(p1)/city(p2)/beachFrontOrLuxuryOrType(p3)
    // /region(p1)/city(p2)/page(p3)
    // /region(p1)/city(p2)/zoom(p3)
    // /region(p1)/city(p2)/neighbourhood(p3)
    // /region(p1)/city(p2)
    // /region(p1)

    // === !IS_CITY_PAGE ===
    // /propertyType(p1)/beachFrontOrLuxury(p2)/coordinates(p3)/page(p4)/zoom(p5)
    // /propertyType(p1)/beachFrontOrLuxury(p2)/coordinates(p3)/page(p4)
    // /propertyType(p1)/beachFrontOrLuxury(p2)/coordinates(p3)/zoom(p4)
    // /propertyType(p1)/coordinates(p2)/page(p3)/zoom(p4)
    // /propertyType(p1)/beachFrontOrLuxury(p2)/page(p3)/zoom(p4)
    // /beachFrontOrLuxury(p1)/coordinates(p2)/page(p3)/zoom(p4)
    // /propertyType(p1)/beachFrontOrLuxury(p2)/coordinates(p3)
    // /propertyType(p1)/coordinates(p2)/page(p3)
    // /propertyType(p1)/coordinates(p2)/zoom(p3)
    // /propertyType(p1)/beachFrontOrLuxury(p2)/page(p3)
    // /beachFrontOrLuxury(p1)/coordinates(p2)/page(p3)
    // /beachFrontOrLuxury(p1)/coordinates(p2)/zoom(p3)
    // /propertyType(p1)/page(p2)/zoom(p3)
    // /coordinates(p1)/page(p2)/zoom(p3)
    // /propertyType(p1)/coordinates(p2)
    // /beachFrontOrLuxury(p1)/page(p2)/zoom(p3)
    // /propertyType(p1)/beachFrontOrLuxury(p2)
    // /propertyType(p1)/page(p2)
    // /coordinates(p1)/page(p2)
    // /beachFrontOrLuxury(p1)/page(p2)
    // /beachFrontOrLuxury(p1)/zoom(p2)
    // /propertyType(p1)
    // /coordinates(p1)
    // /beachFrontOrLuxury(p1)

    const { i18n } = useTranslation();
    const propertiesStore = usePropertiesStore();
    const localizedBeachFront = reformatStringForUrls(getI18nExpByLang(i18n.language, "property.features.beachfront"));
    const localizedLuxury = reformatStringForUrls(getI18nExpByLang(i18n.language, "property.features.luxury"));
    const { p1, p2, p3, p4, p5, p6, p7 } = useParams();
    let cityForMeta,
        coordinates,
        region,
        city,
        neighborhood,
        propertyType,
        page,
        beachFrontOrLuxury,
        isProvincePage,
        isCityPage,
        zoom = "";

    const isGeoZonePage =
        p1 &&
        !p1?.startsWith(PARAMS_NORTH_PREFIX) &&
        !p1?.startsWith(PARAMS_ZOOM_PREFIX) &&
        !p1?.startsWith(PARAMS_BEACHFRONT_OR_LUXURY_PREFIX) &&
        !p1?.startsWith(PARAMS_PAGE_PREFIX) &&
        p1 !== localizedBeachFront &&
        p1 !== localizedLuxury &&
        !isPropertyType(p1);
    let location = undefined;
    let isCityFromCitiesList = false;
    let isExistingPropertyType = false;
    let isNeighborhoodPage = false;
    if (isGeoZonePage) {
        if (p7) {
            page = p7.replace(PARAMS_PAGE_PREFIX, "");
        }

        if (p6) {
            if (p6.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p6.replace(PARAMS_PAGE_PREFIX, "");
            } else if (p6.startsWith(PARAMS_ZOOM_PREFIX)) {
                zoom = p6.replace(PARAMS_ZOOM_PREFIX, "");
            }
        }

        if (p5) {
            if (p5 === localizedBeachFront || p5 === localizedLuxury) {
                beachFrontOrLuxury = p5.replace(PARAMS_BEACHFRONT_OR_LUXURY_PREFIX, "");
            } else if (p4.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p4.replace(PARAMS_PAGE_PREFIX, "");
            } else if (p4.startsWith(PARAMS_ZOOM_PREFIX)) {
                zoom = p4.replace(PARAMS_ZOOM_PREFIX, "");
            }
        }

        if (p4) {
            if (p4 === localizedBeachFront || p4 === localizedLuxury) {
                beachFrontOrLuxury = p4.replace(PARAMS_BEACHFRONT_OR_LUXURY_PREFIX, "");
            } else if (p4.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p4.replace(PARAMS_PAGE_PREFIX, "");
            } else if (p4.startsWith(PARAMS_ZOOM_PREFIX)) {
                zoom = p4.replace(PARAMS_ZOOM_PREFIX, "");
            } else {
                propertyType = p4;
                const labelType = getI18nExpByLang(i18n.language, `property.types`);
                isExistingPropertyType = !Object.values(labelType).includes(labelType);
            }
        }

        if (p3) {
            if (p3 === localizedBeachFront || p3 === localizedLuxury) {
                beachFrontOrLuxury = p3.replace(PARAMS_BEACHFRONT_OR_LUXURY_PREFIX, "");
            } else if (p3.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p3.replace(PARAMS_PAGE_PREFIX, "");
            } else if (p3.startsWith(PARAMS_ZOOM_PREFIX)) {
                page = p3.replace(PARAMS_ZOOM_PREFIX, "");
            } else if (!isPropertyType(p3)) {
                neighborhood = p3;
                isNeighborhoodPage = true;
            } else {
                propertyType = p3;
                const labelType = getI18nExpByLang(i18n.language, `property.types`);
                isExistingPropertyType = !Object.values(labelType).includes(labelType);
            }
        }

        if (p2) {
            if (p2 === localizedBeachFront || p2 === localizedLuxury) {
                beachFrontOrLuxury = p2.replace(PARAMS_BEACHFRONT_OR_LUXURY_PREFIX, "");
            } else if (p2.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p2.replace(PARAMS_PAGE_PREFIX, "");
            } else if (p2.startsWith(PARAMS_ZOOM_PREFIX)) {
                page = p2.replace(PARAMS_ZOOM_PREFIX, "");
            } else if (isPropertyType(p2)) {
                propertyType = p2;
                const labelType = getI18nExpByLang(i18n.language, `property.types`);
                isExistingPropertyType = !Object.values(labelType).includes(labelType);
            } else {
                isCityPage = true;
                city = p2;
                region = p1;
                location = MEXICO_CITIES.find(
                    (ca_city) =>
                        slugify(ca_city.name).toLowerCase() ===
                        slugify(
                            reformatUtf8Url(
                                propertiesStore.addressParams?.city
                                    ? propertiesStore.addressParams.city
                                    : p2.replace(/_/g, " "),
                                false,
                            ).toLowerCase(),
                        ),
                );

                if (location) {
                    city = location.name;
                    cityForMeta = location.name;
                    region = location.region;
                    isCityFromCitiesList = true;
                } else {
                    cityForMeta =
                        p2.replace(/_/g, " ").split(",").length > 0
                            ? p2.replace(/_/g, " ").split(",")[0]
                            : p2.replace(/_/g, " ");
                }
            }
        } else {
            region = p1;
            cityForMeta = reformatUrlForOriginalName(p1);
        }
        region = p1;
    } else {
        if (p5) {
            page = p5.replace(PARAMS_ZOOM_PREFIX, "");
        }
        if (p4) {
            if (p4.startsWith(PARAMS_ZOOM_PREFIX)) {
                zoom = p4.replace(PARAMS_ZOOM_PREFIX, "");
            }
            if (p4.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p4.replace(PARAMS_PAGE_PREFIX, "");
            }
        }

        if (p3) {
            if (p3.startsWith(PARAMS_ZOOM_PREFIX)) {
                zoom = p3.replace(PARAMS_ZOOM_PREFIX, "");
            } else if (p3.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p3.replace(PARAMS_PAGE_PREFIX, "");
            } else if (p3.startsWith(PARAMS_NORTH_PREFIX)) {
                const _coordinates = p1.split(",");
                coordinates = getMapCoordinatesFromParams(_coordinates);
                propertiesStore.searchParams.mapCoordinates = coordinates;
            }
        }

        if (p2) {
            if (p2.startsWith(PARAMS_ZOOM_PREFIX)) {
                zoom = p2.replace(PARAMS_ZOOM_PREFIX, "");
            } else if (p2.startsWith(PARAMS_PAGE_PREFIX)) {
                page = p2.replace(PARAMS_PAGE_PREFIX, "");
            } else if (p2 === localizedBeachFront || p2 === localizedLuxury) {
                beachFrontOrLuxury = p2.replace(PARAMS_BEACHFRONT_OR_LUXURY_PREFIX, "");
            } else if (p2.startsWith(PARAMS_NORTH_PREFIX)) {
                const _coordinates = p1.split(",");
                coordinates = getMapCoordinatesFromParams(_coordinates);
                propertiesStore.searchParams.mapCoordinates = coordinates;
            }
        }

        if (p1) {
            if (p1 === localizedBeachFront || p1 === localizedLuxury) {
                beachFrontOrLuxury = p1.replace(PARAMS_BEACHFRONT_OR_LUXURY_PREFIX, "");
            } else if (p1.startsWith(PARAMS_NORTH_PREFIX)) {
                const _coordinates = p1.split(",");
                coordinates = getMapCoordinatesFromParams(_coordinates);
                propertiesStore.searchParams.mapCoordinates = coordinates;
            } else {
                propertyType = p1;
                const labelType = getI18nExpByLang(i18n.language, `property.types`);
                isExistingPropertyType = !Object.values(labelType).includes(labelType);
            }
        }
    }
    if (isGeoZonePage && !isCityPage && !isNeighborhoodPage) isProvincePage = true;
    return {
        isGeoZonePage,
        isExistingPropertyType,
        isCityFromCitiesList,
        location,
        cityForMeta,
        city,
        region,
        coordinates,
        propertyType,
        page,
        zoom,
        beachFrontOrLuxury: beachFrontOrLuxury,
        isLuxury: beachFrontOrLuxury === localizedLuxury,
        neighborhood,
        isNeighborhoodPage,
        isProvincePage,
    };
}

export function useHomePage() {
    const url = useLocation().pathname;
    const urlSplit = url.split("/");
    return urlSplit.length < 3 && (urlSplit[1] === "" || Object.keys(sharedConfig.languages).includes(urlSplit[1]));
}

export function usePage() {
    const url = useLocation().pathname;
    const isHomePage = useHomePage();
    const isBuyPage = url.startsWith(URLS.buy());
    const isPropertyUnitPage = url.startsWith(URLS.property(""));
    return {
        isHomePage,
        isBuyPage,
        isPropertyUnitPage,
    };
}

export function useForceRefreshPage(initUrl?: string) {
    const location = useLocation();
    const [url, setUrl] = useState(initUrl ?? location.pathname + location.search);

    const isBuyingOrIsRentingUrl = url && url.startsWith(URLS.buy()) && url.split("/").length > 2;

    const refreshPage = (newUrl?: string) =>
        setTimeout(() => {
            window.location.href = newUrl ?? url;
        }, 50);

    return { isBuyingOrIsRentingUrl, refreshPage, setUrl, url };
}

import React from "react";
import { IconBed } from "properties/propertyPage/icons/IconBed";
import { IconBath } from "properties/propertyPage/icons/IconBath";
import { IconType } from "properties/propertyPage/icons/IconType";
import { usePropertyStore } from "properties/propertyPage/PropertyContext";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import styles from "./_css/propertyIconsInfos.module.css";
import { IconUnitsLeft } from "properties/propertyPage/icons/IconUnitsLeft";

export function PropertyIconsInfos() {
    const propertyStore = usePropertyStore();
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.container, "flex flex-row mv_40")}>
            <div className={clsx(styles.iconContainer, "flex flex-col items-center ")}>
                <IconBed size={60} />
                <div className={"mt_10"}>
                    {propertyStore.property.bedrooms.min}{" "}
                    {!!propertyStore.property.bedrooms.max &&
                        propertyStore.property.bedrooms.min != propertyStore.property.bedrooms.max && (
                            <span>{`- ${propertyStore.property.bedrooms.max}`}</span>
                        )}
                </div>
                <div>{t("words.bedrooms")}</div>
            </div>
            <div className={clsx(styles.iconContainer, "flex flex-col items-center")}>
                <IconBath size={60} />
                <div className={"mt_10"}>
                    {propertyStore.property.bathrooms.min}{" "}
                    {!!propertyStore.property.bathrooms.max &&
                        propertyStore.property.bathrooms.min != propertyStore.property.bathrooms.max && (
                            <span>{`- ${propertyStore.property.bathrooms.max}`}</span>
                        )}
                </div>
                <div>{t("words.bathrooms")}</div>
            </div>
            <div className={clsx(styles.iconContainer, "flex flex-col items-center")}>
                <IconType size={"60"} />
                <div className={clsx("mt_10", styles.propertyTypeText)}>{t("propertyPage.propertyType")}</div>
                <div>{t("property.types." + propertyStore.property.type)}</div>
            </div>
            {propertyStore.property.nbOfUnits > 0 && (
                <div className={clsx(styles.iconContainer, styles.nbOfUnit, "flex flex-col items-center")}>
                    <IconUnitsLeft size={"60"} />
                    <div className={"mt_10 textAlign_center"}>
                        {t("propertyPage.unit_plural", { count: propertyStore.property.nbOfUnits })}
                    </div>
                </div>
            )}
        </div>
    );
}

import { FEES, TFees, TFinancing, TPropertyListingMdl, TRangeNumber } from "properties/_models/PropertyMdl";
import { TMeta, TRootComponent } from "pages/_models/PageMdl";
import dayjs, { Dayjs } from "dayjs";
import { TImage } from "_common/types/baseTypes";
import { TFeatureUnit, TParking } from "admin/units/_models/UnitMdl";

export enum UNIT_TYPE {
    commercial = "commercial",
    showroom = "showroom",
    bedrooms = "bedrooms",
    penthouse = "penthouse",
    garden = "garden",
    h = "h",
    hOne = "hOne",
    hTwo = "hTwo",
    hThree = "hThree",
    hFour = "hFour",
    swimup = "swimup",
    microlot = "microlot",
    macrolot = "macrolot",
    mixte = "mixte",
    residential = "residential",
    commercialLand = "commercialLand",
    suite = "suite",
    buildableModel = "buildableModel",
    none = "none",
}

export const UNIT_TYPE_FILTERED: string[] = [UNIT_TYPE.penthouse, UNIT_TYPE.garden, UNIT_TYPE.swimup];

export type TStatusByPhase = {
    deliveryDate?: dayjs.Dayjs;
    status: INVENTORY_STATUS;
};

export type TNbAvailableByPhase = {
    deliveryDate?: dayjs.Dayjs;
    numberOfUnitAvailable: number;
};

export enum INVENTORY_STATUS {
    AVAILABLE = "AVAILABLE",
    RESERVED = "RESERVED",
    SOLD = "SOLD",
    BLOCKED = "BLOCKED",
    NONE = "NONE",
}

export interface TUnitLocalized {
    title: string;
    description: string;
    urlAlias: string;
    discountText?: string;
    meta: TMeta;
    component: TRootComponent;
}

export type TUnitTypeBaseMdl = {
    _id: string;

    sold: boolean;
    status: INVENTORY_STATUS;
    statusByPhase?: TStatusByPhase[];
    nbAvailableByPhase?: TNbAvailableByPhase[];
    published: boolean;

    price: TRangeNumber;
    priceInPesos: TRangeNumber;
    priceIsNotDisplayed: boolean;
    priceIsInPesos: boolean;
    financing: TFinancing[];
    fees: TFees;
    type: UNIT_TYPE;
    storage: boolean | number;
    parkings: TParking[];
    reserved: boolean;
    maidQuarter: boolean;
    discount: boolean;
    discountText: string;
    features: TFeatureUnit;
    views: TFeatureUnit;
    bathrooms: number;
    powderRooms: number;
    bedrooms: number;
    basementBedrooms: number;
    squareSurface: TRangeNumber;
    terraceSurface: TRangeNumber;
    landSurface: TRangeNumber;
    unitDescription: string;
    numberOfUnit: number;
    numberOfAvailableUnits: number;
    variant: string;
    isUpdateFromConversion: boolean;
    privatePool: TRangeNumber;
    privateTerrace: TRangeNumber;

    photos: TImage[];
    floorPlans: TImage[];
    showroom: TImage[];
    constructProgress: TImage[];
    furnitures: TImage[];
    nbOfLevels: number;

    visit3D: string;
    video: string;
    video2: string;

    position: number;
    deliveryDates: dayjs.Dayjs[];
    firstDayOnSite: Dayjs;
    createdAt: Dayjs;
    updatedAt: Dayjs;

    placeholdersUnitsIds?: string[];
};

export type TUnitTypeMdl = TUnitTypeBaseMdl & {
    localized: {
        fr: TUnitLocalized;
        es: TUnitLocalized;
        en: TUnitLocalized;
        zh: TUnitLocalized;
    };
    property: string;
};

export type TUnitTypeListingMdl = TUnitTypeBaseMdl & {
    localized: TUnitLocalized;
    urlAlias: {
        [lang: string]: string;
    };
    property: TPropertyListingMdl;
};

export type TUnitDashboardListingMdl = TUnitTypeListingMdl & {
    _localized: {
        fr: TUnitLocalized;
        es: TUnitLocalized;
        en: TUnitLocalized;
        zh: TUnitLocalized;
    };
    stats: {
        printed: {
            count: number;
            date: Date;
        };
        visited: {
            count: number;
            date: Date;
        };
        favorited: {
            count: number;
        };
    };
};

export function createDefaultUnit(
    propertyId: string,
    financing?: TFinancing[],
    fees?: TFees,
): Omit<TUnitTypeMdl, "_id"> {
    return {
        localized: {
            fr: {
                title: "",
                description: "",
                meta: { title: "", description: "" },
                urlAlias: "",
                component: {
                    id: "div",
                    props: {
                        style: { padding: 0 },
                        children: [{ id: "container", props: { style: {}, children: [] } }],
                    },
                },
            },
            es: {
                title: "",
                description: "",
                meta: { title: "", description: "" },
                urlAlias: "",
                component: {
                    id: "div",
                    props: {
                        style: { padding: 0 },
                        children: [{ id: "container", props: { style: {}, children: [] } }],
                    },
                },
            },
            en: {
                title: "",
                description: "",
                meta: { title: "", description: "" },
                urlAlias: "",
                component: {
                    id: "div",
                    props: {
                        style: { padding: 0 },
                        children: [{ id: "container", props: { style: {}, children: [] } }],
                    },
                },
            },
            zh: {
                title: "",
                description: "",
                meta: { title: "", description: "" },
                urlAlias: "",
                component: {
                    id: "div",
                    props: {
                        style: { padding: 0 },
                        children: [{ id: "container", props: { style: {}, children: [] } }],
                    },
                },
            },
        },
        sold: false,
        published: false,
        property: propertyId,

        price: { min: 0, max: 0 },
        priceInPesos: { min: 0, max: 0 },
        privatePool: { min: 0, max: 0 },
        privateTerrace: { min: 0, max: 0 },
        priceIsInPesos: false,
        financing: financing ?? [
            {
                signin: 0,
                building: 0,
                delivery: 0,
                deeding: 0,
                discount: 0,
            },
        ],
        fees: fees ?? { type: FEES.toBeDetermined, value: 0 },
        priceIsNotDisplayed: false,
        type: UNIT_TYPE.showroom,
        parkings: [],
        deliveryDates: [],
        storage: false,
        reserved: false,
        maidQuarter: false,
        status: INVENTORY_STATUS.AVAILABLE,
        discount: false,
        discountText: "",
        features: {},
        views: {},
        bathrooms: 0,
        powderRooms: 0,
        bedrooms: 0,
        basementBedrooms: 0,
        squareSurface: { min: 0, max: 0 },
        terraceSurface: { min: 0, max: 0 },
        landSurface: { min: 0, max: 0 },
        nbOfLevels: 0,
        unitDescription: "",
        isUpdateFromConversion: false,
        numberOfUnit: 0,
        numberOfAvailableUnits: 0,

        photos: [],
        floorPlans: [],
        constructProgress: [],
        furnitures: [],
        showroom: [],

        visit3D: "",
        video: "",
        video2: "",
        variant: "",

        position: 0,

        firstDayOnSite: dayjs(),
        createdAt: dayjs(),
        updatedAt: dayjs(),
    };
}

import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core";
import { Meta } from "_common/_utils/Meta";
import { Breadcrumb } from "breadcrumb/Breadcrumb";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "pages/_css/pageNotFound.module.css";
import NotFound from "shared/assets/images/404.svg";
import { URLS } from "_configs/URLS";
import React from "react";

export default () => {
    const { t } = useTranslation();
    return (
        <Container>
            <Meta title={t("errors.pageNotFound")} description={t("errors.pageNotFound")} />
            <Breadcrumb currentItem={{ itemLabelKey: "errors.pageNotFound" }} />
            <div className="flex flex-col items-center items-center textAlign_center mv_60">
                <LazyLoadImage className={styles.img} src={NotFound} alt={t("errors.pageNotFound")} />
                <div className="mt_20">{t("errors.pageNotFound")}</div>
                <div className="mt_20">
                    <a className={"flex flex-row items-center"} href={URLS.home()} title={t("words.home")}>
                        <i className="icon-chevron-left mr_3" />
                        {t("backToHome")}
                    </a>
                </div>
            </div>
        </Container>
    );
};

import React from "react";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./_css/dialog.module.css";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import { Dialog as MuiDialog } from "@material-ui/core";

type Props = Omit<DialogProps, "onClose"> & {
    onClose: () => void;
    closeCross?: boolean;
    closeSize?: "inherit" | "default" | "small" | "large";
    withoutPlaceholder?: boolean;
    isTransparentBg?: boolean;
    stickyCross?: boolean;
};

export function Dialog({ closeCross, closeSize, withoutPlaceholder, stickyCross, ...props }: Props) {
    return (
        <MuiDialog {...props}>
            {closeCross && (
                <div
                    onClick={() => props.onClose?.()}
                    className={clsx(
                        styles.cross,
                        "absolute right-0 flex flex-row p-3 justify-end cursor-pointer z-[1020]",
                        {
                            ["p-7"]: closeSize === "large",
                            ["sticky top-0"]: stickyCross,
                        },
                    )}
                >
                    <CloseIcon
                        fontSize={closeSize ? closeSize : "default"}
                        color={props.isTransparentBg ? "secondary" : "inherit"}
                    />
                </div>
            )}
            {closeCross && !withoutPlaceholder && (
                <div
                    className={clsx(styles.crossPlaceHolderSmall, {
                        [styles.crossPlaceHolderLarge]: closeSize === "large",
                        [styles.transparentBg]: props.isTransparentBg,
                    })}
                />
            )}
            {props.children}
        </MuiDialog>
    );
}

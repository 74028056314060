import React from "react";
import { TCardProperty } from "properties/_models/PropertyMdl";
import { useTranslation } from "react-i18next";
import arrow from "../../../assets/images/rightArrowTiny.svg";

type Props = {
    property: Pick<TCardProperty, "nbOfUnitsFiltered" | "nbOfUnits">;
};

export function PropertyCardHeader({ property }: Props) {
    const { t } = useTranslation();

    return (
        <div className={"flex flex-row justify-between"}>
            <div className={"text_small"}>
                {property?.nbOfUnitsFiltered > 0
                    ? t("property.unitsFiltered", { count: property.nbOfUnitsFiltered })
                    : t("property.unitsAvailable", { count: property.nbOfUnits })}
            </div>
            <div className={"flex flex-row items-center text_xxsmall"}>
                {t("words.viewAll")}
                <img src={arrow} alt={"arrow"} className={"ml_5"} />
            </div>
        </div>
    );
}

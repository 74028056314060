import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { useCommunityStore } from "communities/CommunityContext";
import { CommunitiesDialogSlider } from "communities/components/CommunitiesDialogSlider";
import extandImage from "../assets/images/communities/extandImage.svg";

SwiperCore.use([Navigation]);
export function CommunitiesImageSlider() {
    const communityStore = useCommunityStore();
    const [modalVisible, setModalVisible] = React.useState(false);
    const [currentImage, setCurrentImage] = React.useState(0);

    const NAME = "communitiesImageSlider";

    return (
        <div id={NAME} className={"relative"}>
            <Swiper
                loop
                slidesPerView={1}
                centeredSlides
                navigation={{
                    prevEl: `.swiper-button-prev.${NAME}`,
                    nextEl: `.swiper-button-next.${NAME}`,
                }}
                spaceBetween={20}
            >
                {communityStore.community.photos?.map((picture, idx) => (
                    <SwiperSlide key={idx}>
                        <div className={"relative flex justify-center "}>
                            <img
                                className={"rounded-lg w-full !max-h-[500px] object-cover"}
                                src={picture.url}
                                alt={"community_picture"}
                            />
                            <div
                                className={
                                    "absolute bottom-5 right-5 z-10 text-white cursor-pointer bg-[var(--color-primary)] rounded"
                                }
                                onClick={() => {
                                    setCurrentImage(idx);
                                    setModalVisible(true);
                                }}
                            >
                                <img src={extandImage} alt={"extand-image"} />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className={`swiper-button-prev ${NAME}`} />
            <div className={`swiper-button-next ${NAME}`} />
            <CommunitiesDialogSlider
                currentImage={currentImage}
                isOpen={modalVisible}
                setIsOpen={() => setModalVisible(false)}
            />
        </div>
    );
}

import React from "react";
import { useTranslation } from "react-i18next";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";

type Props = {
    type?: RESOURCE;
};

export function NoResults(props: Props) {
    const { t } = useTranslation();
    return (
        <div className={"textAlign_center"}>
            {props.type === RESOURCE.PROPERTIES
                ? t("errors.noProperties")
                : props.type === RESOURCE.UNIT_TYPES
                ? t("errors.noUnits")
                : t("errors.noResults")}
        </div>
    );
}

import React from "react";
import { propertiesBuyingStore } from "properties/_stores/propertiesBuyingStore";
import { CardTypesList } from "home/CardTypesList";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";
import { useTranslation } from "react-i18next";
import { usePropertyPageContext } from "properties/PropertyPageContext";
import { Container } from "@material-ui/core";
import { BREAKPOINTS_SECTION_CARDS } from "properties/components/BREAKPOINTS_SECTION_CARDS";

type Props = {
    geoZoneName?: string;
};

export function PropertiesTypesSection({ geoZoneName }: Props) {
    propertiesBuyingStore.fetchSortedTypes();
    const { t } = useTranslation();
    const { mapOrListSelected } = usePropertyPageContext();
    return (
        <Container className={"max-w-[1260px]"}>
            <h2 className={"mb-4 text-center"}>
                {geoZoneName
                    ? t(`propertiesPage.propertyTypeSection.titleGeozone`, { geoZoneName: geoZoneName })
                    : t(`propertiesPage.propertyTypeSection.title`)}
            </h2>
            <LoadableFromLoading
                noSpinner
                loading={propertiesBuyingStore.sortTypesState}
                renderer={(_status, error) => {
                    if (error) return null;
                    return (
                        <CardTypesList
                            removeGeoZone={false}
                            items={propertiesBuyingStore.sortTypes}
                            breakPoints={BREAKPOINTS_SECTION_CARDS[mapOrListSelected]}
                        />
                    );
                }}
            />
        </Container>
    );
}

import React from "react";
import { USER_MENU_ITEMS } from "main/header/menu/MENU_ITEMS";
import { userStore } from "users/_stores/userStore";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { TLang } from "_configs/sharedConfig";
import { useTranslation } from "react-i18next";
import styles from "main/header/menu/_css/typeSubMenu.module.css";

export function MenuUser() {
    const { t } = useTranslation();

    const handleMenuItemClick = (itemAction: { (): void }) => {
        if (itemAction) {
            itemAction();
        }
    };

    return (
        <ul className={styles.container}>
            {USER_MENU_ITEMS.map((menuItem) => {
                if (
                    !menuItem.roles ||
                    (userStore.user?.roles &&
                        userStore.user.roles.find((userRole) => menuItem.roles && menuItem.roles?.includes(userRole)))
                ) {
                    return (
                        <li key={menuItem.key}>
                            {menuItem.itemLink ? (
                                <Link to={menuItem.itemLink(i18next.language as TLang)}>
                                    {t(menuItem.itemLabelKey)}
                                </Link>
                            ) : (
                                <button onClick={() => handleMenuItemClick(menuItem.itemAction)}>
                                    {t(menuItem.itemLabelKey)}
                                </button>
                            )}
                        </li>
                    );
                }
            })}
        </ul>
    );
}

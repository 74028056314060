import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./_css/featureOptions.module.css";
import clsx from "clsx";
import { useUnitStore } from "units/UnitContext";

export function UnitFeatures() {
    const { t } = useTranslation();
    const { unitType } = useUnitStore();
    if (!unitType.features) return null;
    return (
        <div className={clsx(styles.list, "mv_20 flex flex-row")}>
            {unitType.features && (
                <div className="flex-1 p_40">
                    <h2 className={clsx("textAlign_center", styles.title)}>{t("unit.featuresTitle")}</h2>
                    <ul className={"flex flex-col"}>
                        {Object.keys(unitType.features).map((feature) => {
                            if (!unitType.features[feature]) return null;
                            return <li key={feature}>{t(`unit.features.${feature}`)}</li>;
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
}

import React from "react";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import clsx from "clsx";
import { IconButton, Tooltip } from "@material-ui/core";
import styles from "./_css/compareBtn.module.css";
import { comparisonStore } from "shared/compare/_store/ComparisonStore";
import { UiButton } from "_common/ui/mui/buttons/UiButton";
import { IconCompare } from "properties/propertyPage/icons/IconCompare";

type Props = {
    id: string;
    type: RESOURCE;
    button?: boolean;
    isItemPage?: boolean;
    isForCard?: boolean;
    label?: string;
    size?: string;
    direction?: "row" | "column";
    weight?: number;
    color?: string;
    variant?: "contained" | "outlined";
    noShadow?: boolean;
    backgroundFirst?: string;
    backgroundSecond?: string;
    className?: string;
};

export function CompareBtn(props: Props) {
    return (
        <div
            className={clsx(
                props.direction ? `flex_${props.direction}_center` : "flex items-center justify-center",
                styles.container,
            )}
        >
            {props.button ? (
                <Tooltip title={`Compare ${props.type}`}>
                    <UiButton
                        aria-label={"Compare button"}
                        onClick={() => {
                            comparisonStore.setIsDisplayedOverlay(true);
                            comparisonStore.addItem(props.type, props.id);
                        }}
                        className={clsx(
                            "p_5",
                            styles.button,
                            {
                                [styles.isItemPage]: props.isItemPage,
                                [styles.noShadow]: props.noShadow,
                            },
                            props.className,
                        )}
                        variant={props.variant ?? "contained"}
                    >
                        <IconCompare color={props.color} size={props.size ?? "44"} />
                        {props.label && (
                            <div
                                className={clsx(styles.label, "ml_5")}
                                style={{ fontWeight: props.weight, color: props.color }}
                            >
                                {props.label}
                            </div>
                        )}
                    </UiButton>
                </Tooltip>
            ) : (
                <>
                    <Tooltip title={`Compare ${props.type}`}>
                        <IconButton
                            aria-label={"Compare button"}
                            className={clsx(
                                "p_5",
                                styles.iconCompare,
                                {
                                    [styles.isItemPage]: props.isItemPage,
                                    [styles.isForCard]: props.isForCard,
                                },
                                props.className,
                            )}
                            onClick={() => {
                                comparisonStore.setIsDisplayedOverlay(true);
                                comparisonStore.addItem(props.type, props.id);
                            }}
                        >
                            <IconCompare
                                color={props.color}
                                size={props.size}
                                backgroundColorFirst={props.backgroundFirst}
                                backgroundColorSecond={props.backgroundSecond}
                            />
                        </IconButton>
                    </Tooltip>
                    {props.label && (
                        <div className={styles.label} style={{ fontWeight: props.weight, color: props.color }}>
                            {props.label}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

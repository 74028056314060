import React from "react";
import { panelStore } from "main/header/panel/_stores/panelStore";
import { MobileMenu } from "main/header/menu/mobile/MobileMenu";
import { IconComponent } from "components/base/icon/IconComponent";
import { IconButton, NoSsr } from "@material-ui/core";
import { HeaderContactMobile } from "main/header/HeaderContactMobile";
import { CurrencySelector } from "main/header/CurrencySelector";

export function HeaderContentMobile() {
    return (
        <div className={"flex flex-row items-center"}>
            <HeaderContactMobile />
            <NoSsr>
                <CurrencySelector />
            </NoSsr>
            <IconButton
                size={"medium"}
                name={"menu mobile"}
                onClick={() =>
                    panelStore.open({
                        children: <MobileMenu />,
                        onClose: () => panelStore.close(),
                        withSpinner: false,
                        opened: true,
                    })
                }
                title={"menu mobile"}
            >
                <IconComponent
                    icon={"menu"}
                    aria-labelledby={"menu mobile"}
                    title={"menu mobile"}
                    aria-label={"menu mobile"}
                />
            </IconButton>
        </div>
    );
}

import React, { lazy } from "react";
import { observer } from "mobx-react";
import { Meta } from "_common/_utils/Meta";
import { geoZonesStore } from "geoZones/_stores/geoZonesStore";
import { PropertiesSort } from "properties/PropertiesSort";
import clsx from "clsx";
import { usePropertiesPageMeta } from "properties/usePropertiesPageMeta";
import { PROPERTY_TYPE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { ListStore } from "_common/list/ListStore";
import { usePropertiesStore } from "properties/PropertiesPageContext";
import { useSearchPageParams } from "_common/_utils/hookUtils";
import { URLS } from "_configs/URLS";
import { PropertiesListLoader } from "properties/PropertiesListLoader";
import { PropertiesListBreadcrumb } from "properties/PropertiesListBreadcrumb";
import { GEO_ZONE_TYPE } from "geoZones/_models/GeoZoneMdl";
import { getUrlWithPagination } from "_common/_utils/pageUtils";
import { useHistory } from "react-router-dom";
import Suspenser from "_common/loaders/Suspenser";
import { usePropertyPageContext } from "properties/PropertyPageContext";
import { PropertiesCount } from "properties/PropertiesCount";
import { PropertyCardListing } from "properties/propertyCard/PropertyCardListing";
import { PropertiesViewPagination } from "properties/PropertiesViewPagination";
import { PropertiesTagsSection } from "properties/PropertiesTagsSection";
import { PropertiesTypesSection } from "properties/PropertiesTypesSection";
import { PropertiesFaq } from "properties/PropertiesFaq";
import { MAP_OR_LIST_SELECTED } from "properties/components/BREAKPOINTS_SECTION_CARDS";
import { NoResults } from "pages/NoResults";
import { RESOURCE } from "admin/_common/photos/AdminPhotosStore";
import { LoadableFromLoading } from "_common/loaders/LoadableFromLoading";

type Props = {
    listStore: ListStore<TPropertyListingMdl>;
};

const LazyPropertiesChildGeoZones = lazy(() => import("properties/components/PropertiesChildGeoZones"));
const LazyPropertiesNearbyGeoZones = lazy(() => import("properties/components/PropertiesNearbyGeoZones"));

export const PropertiesViewList = observer(({ listStore }: Props) => {
    const propertiesStore = usePropertiesStore();
    const history = useHistory();
    const { mapOrListSelected } = usePropertyPageContext();
    const { isGeoZonePage, isCityFromCitiesList, isLuxury } = useSearchPageParams();
    const { title, metaTitle, metaDescription, geoZoneName } = usePropertiesPageMeta(propertiesStore, listStore);

    if (!listStore.isCurrentPageExisting) {
        listStore.setSelectedPage(1);
        history.push(getUrlWithPagination(history.location.pathname, 1) + history.location.search);
    }
    const noIndex = isGeoZonePage && !isCityFromCitiesList && !geoZonesStore.geoZone;
    const propertyTypeFilter: PROPERTY_TYPE | undefined = listStore.filters.find((filter) => filter.id === "type")
        ?.value[0];

    const geoZone = geoZonesStore.geoZone;
    const purpose = propertiesStore.purpose;
    // console.log("listStore.paginatedItems", listStore.paginatedItems);
    return (
        <>
            <Meta title={metaTitle} description={metaDescription} url={noIndex ? URLS.home() : undefined} />
            <div className={"md:hidden pv_10"}>
                <PropertiesTagsSection listStore={listStore} />
            </div>
            {!noIndex && <h1 className={"text-xl"}>{title}</h1>}
            <div className="flex items-center justify-between my-5">
                <PropertiesSort listStore={listStore} />
                <PropertiesCount listStore={listStore} />
            </div>
            {listStore.count === 0 && <NoResults type={RESOURCE.PROPERTIES} />}
            <div
                className={clsx("grid grid-cols-1 gap-4", {
                    ["md:grid-cols-2 lg:grid-cols-3"]: mapOrListSelected === MAP_OR_LIST_SELECTED.LIST,
                    ["xl:grid-cols-2"]: mapOrListSelected === MAP_OR_LIST_SELECTED.MAP,
                })}
            >
                <LoadableFromLoading
                    loading={listStore.currentLoadingState}
                    contentRender={<PropertiesListLoader />}
                    renderer={() => {
                        return (
                            <>
                                {listStore.paginatedItems.map((property) => {
                                    if (!property) return null;
                                    return (
                                        <PropertyCardListing
                                            key={property._id}
                                            property={property}
                                            onMouseEnter={() => {
                                                propertiesStore.setMapPropertySelected(property);
                                            }}
                                            onMouseLeave={() => {
                                                propertiesStore.setMapPropertySelected.cancel();
                                                propertiesStore.setMapPropertySelected(undefined);
                                            }}
                                            isLuxury={isLuxury}
                                        />
                                    );
                                })}
                            </>
                        );
                    }}
                />
            </div>
            <div id={"PropertiesViewListPaginator"} className={"flex justify-center rela mt-10"}>
                <PropertiesViewPagination listStore={listStore} />
            </div>
            <div className={"flex items-center justify-center py-4"}>
                <PropertiesListBreadcrumb />
            </div>
            <div className={"py-3"}>
                <PropertiesTypesSection geoZoneName={geoZoneName} />
            </div>
            <div className={"hidden md:block py-3"}>
                <PropertiesTagsSection listStore={listStore} />
            </div>
            <div className={"py-3"}>
                <PropertiesFaq geoZone={geoZone} purpose={purpose} />
            </div>
            <Suspenser noText noSpinner>
                <LazyPropertiesNearbyGeoZones propertyType={propertyTypeFilter} />
            </Suspenser>
            {geoZonesStore.geoZone?.type !== GEO_ZONE_TYPE.NEIGHBORHOOD && (
                <Suspenser noText noSpinner>
                    <LazyPropertiesChildGeoZones propertyType={propertyTypeFilter} />
                </Suspenser>
            )}
        </>
    );
});
